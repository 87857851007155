import React from 'react';
import './docLinks.css';
import pdflogo from './pdf.png';
import excellogo from './excel.png';

function DocLinks(props) {
  const { title } = props;
  return (
    <div className="main-docLinks">
      <h2 className="docLinks-title">{title}</h2>
      <div className="card-doc">
        <div className="titulos">
          <h3>Titulo del documento</h3>
          <h3>Ver documento</h3>
        </div>
        {props.Docts.map((Doct) => (
          <div className="contenedor-link" key={Doct.id}>
            <div className="botton-docLinks">{Doct.title}</div>
            <div className="contenedor-links-imagenes-docs">
              {Doct.linkecxel && (
                <a className="a-docLinks" href={Doct.linkecxel} target="_blank" rel="noopener noreferrer">
                  <img src={excellogo} alt="descargar archivo" />
                </a>
              )}
              {Doct.linkpdf && (
                <a className="a-docLinks" href={Doct.linkpdf} target="_blank" rel="noopener noreferrer">
                  <img src={pdflogo} alt="descargar archivo" />
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DocLinks;
