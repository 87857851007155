import React from 'react';
import './ImageGallery.css';

function ImageGallery(props) {
  const { title, images } = props;

  return (
    <div className="image-gallery">
      <h2 className="image-gallery-title">{title}</h2>
      <div className="image-gallery-grid">
        {images.map((image, index) => (
          <div key={index} className="image-gallery-item">
            <img src={image} alt={`Imagen ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageGallery;