import React from 'react';
import { useNavigate } from 'react-router-dom';

import Mariposa from './iconos/Icono Biodiversidad-1.png'
import Areas from './iconos/Logo SEAC.png'
import forest from './iconos/Icono Bosque 4 png.png'
import Residuos from './iconos/Icono Residuos.png'
import Atmosfera from './iconos/Icono Atmosfera-1.png'
import Impacto from './iconos/Icono Regulación Ambiental-1.png'
import Bancos from './iconos/Icono Bancos de Mat Petr 2-1.png'
import Planeacion from './iconos/Logo OET 2.png'
import Educacion from './iconos/Icono Cultura Ambiental-1.png'
import Suelo from './iconos/Icono Suelo-1.png'
import Agua from './iconos/Icono Agua-1.png'
import Cambio from './iconos/Icono Cambio Clim-1.png'
import Proteccion from './iconos/logo proam vert.png'
import Zoologico from './iconos/Icono Zoo-1 (1).png'
import Instrumentos from './iconos/Icono Proyectos.jpg';
import Demografia from './iconos/Demografía Mich 2-1.png';

import './menu-contextual.css';

function Menucontextual() {
    const navigate = useNavigate();

    const handleClick = (content) => {
        window.scrollTo(0, 0);
        navigate(`/${content}`);
    };
    return (
        <div className='contenedor'>
            <div className='sub-menus'>


                <div className='sub-menu' onClick={() => handleClick('biodiversidad')}>
                    <div className='card'>
                        <div className='front'>
                            <img src={Mariposa} alt="Imagen 1" className='imagen-icono' />
                            <h2 className='encabezado'>Biodiversidad</h2>
                        </div>
                        <div className='back'>
                            <div className='leyenda'>
                                Una vez que una especie se extingue ninguna ley puede hacerla regresar. Allen M. Solomon.
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sub-menu' onClick={() => handleClick('areas-conservacion')}>
                    <div className='card'>
                        <div className='front'>
                            <img src={Areas} alt="Imagen 1" className='imagen-icono' />
                            <h2 className='encabezado'>
                                Sistema Estatal de Áreas de Conservación
                            </h2>
                        </div>
                        <div className='back'>
                            <div className='leyenda'>
                                La conservación es un estado de armonía entre hombre y tierra. Aldo Leopold.
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sub-menu' onClick={() => handleClick('recursos-forestales')}>
                    <div className='card'>
                        <div className='front'>
                            <img src={forest} alt="Imagen 1" className='imagen-icono' />
                            <h2 className='encabezado'>
                                Recursos Forestales
                            </h2>
                        </div>
                        <div className='back'>
                            <div className='leyenda'>
                                Adquirir un conocimiento más profundo de la dinámica ecológica, planteará esquemas más eficientes del uso de nuestros recursos naturales renovables. Efraim Hernández Xolocotzi.
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sub-menu' onClick={() => handleClick('residuos')}>
                    <div className='card'>
                        <div className='front'>
                            <img src={Residuos} alt="Imagen 1" className='imagen-icono' />
                            <h2 className='encabezado'>
                                Residuos
                            </h2>
                        </div>
                        <div className='back'>
                            <div className='leyenda'>
                                Debes hacer que el reciclaje se convierta en una forma de vivir. Mario Vargas Llosa.
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sub-menu' onClick={() => handleClick('atmosfera')}>
                    <div className='card'>
                        <div className='front'>
                            <img src={Atmosfera} alt="Imagen 1" className='imagen-icono' />
                            <h2 className='encabezado'>
                                Atmósfera
                            </h2>
                        </div>
                        <div className='back'>
                            <div className='leyenda'>
                                La solución a los problemas ambientales, no es la responsabilidad de los científicos, sino de la sociedad en su totalidad. Mario Molina.
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sub-menu' onClick={() => handleClick('impacto')}>
                    <div className='card'>
                        <div className='front'>
                            <img src={Impacto} alt="Imagen 1" className='imagen-icono' />
                            <h2 className='encabezado'>
                                Impacto y Riesgo Ambiental
                            </h2>
                        </div>
                        <div className='back'>
                            <div className='leyenda'>
                                Lo que necesitamos son grandes instituciones que verdaderamente protejan el ambiente. Luis Zambrano.
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sub-menu' onClick={() => handleClick('bancos')}>
                    <div className='card'>
                        <div className='front'>
                            <img src={Bancos} alt="Imagen 1" className='imagen-icono' />
                            <h2 className='encabezado'>
                                Bancos Pétreos
                            </h2>
                        </div>
                        <div className='back'>
                            <div className='leyenda'>
                                Es necesario informarnos todos, es necesario para evitar prácticas corruptas en torno a la explotación. Inés Vilela.
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sub-menu' onClick={() => handleClick('ordenamiento')}>
                    <div className='card'>
                        <div className='front'>
                            <img src={Planeacion} alt="Imagen 1" className='imagen-icono' />
                            <h2 className='encabezado'>
                                Ordenamiento Ecológico Territorial
                            </h2>
                        </div>
                        <div className='back'>
                            <div className='leyenda'>
                                Es nuestra responsabilidad pasar una tierra sana a las generaciones que nos esperan. Sylvia Dolson.
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sub-menu' onClick={() => handleClick('educacion')}>
                    <div className='card'>
                        <div className='front'>
                            <img src={Educacion} alt="Imagen 1" className='imagen-icono' />
                            <h2 className='encabezado'>
                                Educación Ambiental
                            </h2>
                        </div>
                        <div className='back'>
                            <div className='leyenda'>
                                La única forma, si vamos a mejorar la calidad del medio ambiente, es involucrar a todo el mundo. Richard Rogers.
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sub-menu' onClick={() => handleClick('suelo')}>
                    <div className='card'>
                        <div className='front'>
                            <img src={Suelo} alt="Imagen 1" className='imagen-icono' />
                            <h2 className='encabezado'>
                                Suelo
                            </h2>
                        </div>
                        <div className='back'>
                        <div className='leyenda'>
                            Una nación que destruye su suelo se destruye a sí misma. Franklin D. Roosevelt.
                        </div>
                    </div>
                </div>
            </div>

            <div className='sub-menu' onClick={() => handleClick('agua')}>
                <div className='card'>
                    <div className='front'>
                        <img src={Agua} alt="Imagen 1" className='imagen-icono' />
                        <h2 className='encabezado'>
                            Agua
                        </h2>
                    </div>
                    <div className='back'>
                        <div className='leyenda'>
                            El agua es la fuerza motriz de toda la naturaleza. Leonardo Da Vinci.
                        </div>
                    </div>
                </div>
            </div>

            <div className='sub-menu' onClick={() => handleClick('cambio')}>
                <div className='card'>
                    <div className='front'>
                        <img src={Cambio} alt="Imagen 1" className='imagen-icono' />
                        <h2 className='encabezado'>
                            Cambio Climático
                        </h2>
                    </div>
                    <div className='back'>
                        <div className='leyenda'>
                            El nuevo consenso es que ya no sólo está cambiando el clima, sino que ya vivimos una emergencia climática. Mario Molina.
                        </div>
                    </div>
                </div>
            </div>

            <div className='sub-menu' onClick={() => handleClick('proteccion')}>
                <div className='card'>
                    <div className='front'>
                        <img src={Proteccion} alt="Imagen 1" className='imagen-icono' />
                        <h2 className='encabezado'>
                            Inspección y Vigilancia Ambiental
                        </h2>
                    </div>
                    <div className='back'>
                        <div className='leyenda'>
                            Un acto contra la naturaleza debería ser juzgado tan severamente como uno contra la sociedad u otra persona. Michael W. Fox.
                        </div>
                    </div>
                </div>
            </div>

            <div className='sub-menu' onClick={() => handleClick('zoologico')}>
                <div className='card'>
                    <div className='front'>
                        <img src={Zoologico} alt="Imagen 1" className='imagen-icono' />
                        <h2 className='encabezado'>
                            Zoológico
                        </h2>
                    </div>
                    <div className='back'>
                        <div className='leyenda'>
                            Educación, recreación, investigación y conservación de fauna silvestre, pulmón de la ciudad y refugio natural para otras especies. Benito Juárez.
                        </div>
                    </div>
                </div>
            </div>

            <div className='sub-menu' onClick={() => handleClick('demografia')}>
                <div className='card'>
                    <div className='front'>
                        <img src={Demografia} alt="Imagen 1" className='imagen-icono' />
                        <h2 className='encabezado'>
                            Demografía
                        </h2>
                    </div>
                    <div className='back'>
                        <div className='leyenda'>
                            Mientras las economías, las poblaciones y las demandas de recursos crecen, el tamaño de la Tierra sigue siendo el mismo. Hemos estado utilizando más recursos naturales renovables de los que nuestro planeta puede regenerar. Día de la sobrecapacidad.
                        </div>
                    </div>
                </div>
            </div>






        </div>
        </div >
    );
}

export default Menucontextual;
