import React from 'react';
import './enlaces.css'
function Enlaces() {
    return (
        <div className='main-enlaces'>
            <div className='slide-main'>
                Enlaces estatales:
                <div className='enlace'> <a href="https://medioambiente.michoacan.gob.mx/" target="_blank" rel="noopener noreferrer">https://medioambiente.michoacan.gob.mx/</a> </div>
                <div className='enlace'> <a href="https://proam.michoacan.gob.mx/" target="_blank" rel="noopener noreferrer">https://proam.michoacan.gob.mx/</a> </div>
                <div className='enlace'> <a href="https://ceac.michoacan.gob.mx/" target="_blank" rel="noopener noreferrer">https://ceac.michoacan.gob.mx/</a> </div>
                <div className='enlace'> <a href="https://cofom.michoacan.gob.mx/" target="_blank" rel="noopener noreferrer">https://cofom.michoacan.gob.mx/</a> </div>
                <div className='enlace'> <a href="https://zoomorelia.michoacan.gob.mx/" target="_blank" rel="noopener noreferrer">https://zoomorelia.michoacan.gob.mx/</a> </div>
                <div className='enlace'> <a href="https://celem.michoacan.gob.mx/celem/tablas_ext/" target="_blank" rel="noopener noreferrer">https://celem.michoacan.gob.mx/celem/tablas_ext/</a> </div>
            </div>
            <div className='slide-main'>
                Enlaces federales:
                <div className='enlace'> <a href="https://www.gob.mx/conabio" target="_blank" rel="noopener noreferrer">https://www.gob.mx/conabio</a> </div>
                <div className='enlace'> <a href="https://www.gob.mx/semarnat" target="_blank" rel="noopener noreferrer">https://www.gob.mx/semarnat</a> </div>
                <div className='enlace'> <a href="https://www.inegi.org.mx/" target="_blank" rel="noopener noreferrer">https://www.inegi.org.mx/</a> </div>
                <div className='enlace'> <a href="https://www.semarnat.gob.mx/gobmx/biblioteca/" target="_blank" rel="noopener noreferrer">https://www.semarnat.gob.mx/gobmx/biblioteca/</a> </div>
                <div className='enlace'> <a href="https://www.biodiversidad.gob.mx/" target="_blank" rel="noopener noreferrer">https://www.biodiversidad.gob.mx/</a> </div>
                <div className='enlace'> <a href="http://atlas.inpi.gob.mx/michoacan-2/" target="_blank" rel="noopener noreferrer">http://atlas.inpi.gob.mx/michoacan-2/</a> </div>
                <div className='enlace'> <a href="http://www.atlasnacionalderiesgos.gob.mx/AtlasEstatales/?&NOM_ENT=Michoac%C3%A1n%20de%20Ocampo&CVE_ENT=16" target="_blank" rel="noopener noreferrer">http://www.atlasnacionalderiesgos.gob.mx/AtlasEstatales/</a> </div>

            </div>
            <div className='slide-main'>
                Enlaces de interés:
                <div className='enlace'> <a href="https://www.paismaravillas.mx/" target="_blank" rel="noopener noreferrer">https://www.paismaravillas.mx/</a> </div>
                <div className='enlace'> <a href="https://enciclovida.mx/" target="_blank" rel="noopener noreferrer">https://enciclovida.mx/</a> </div>
                <div className='enlace'> <a href="https://www.naturalista.mx/" target="_blank" rel="noopener noreferrer">https://www.naturalista.mx/</a> </div>
                <div className='enlace'> <a href="https://volcanesdemexico.mx/galeria/" target="_blank" rel="noopener noreferrer">https://volcanesdemexico.mx/galeria/</a> </div>
            </div>
        </div>
    );
}

export default Enlaces;
