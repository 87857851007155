import React from 'react';
import { useNavigate } from 'react-router-dom';
import './subMenuMini.css';
import IcoFotografia from './Icono fotografía-1.png'
import IcoMarco from './Icono Marco Jurídico-1.png'
import IcoSIIAM from './SIIAM Institucional Vertical.png'

function SubMenuMini() { // Changed function name to start with an uppercase letter
    const navigate = useNavigate();

    const handleClick = (content) => {
        window.scrollTo(0, 0);
        navigate(`/${content}`);
    };
    return (
        <div className='main-mini'>
            <div className='contenedor-mini'>
                <div className='botton-mini' onClick={() => handleClick('marco-juridico')}>
                    <img src={IcoMarco} alt="Imagen 1" className='imagen-icono-minimenu' />
                    <div className='main-mini-title'>Prontuario Jurídico Ambiental</div>
                </div>
                <div className='botton-mini oscuro' onClick={() => handleClick('sistema-integral')}>
                    <img src={IcoSIIAM} alt="Imagen 1" className='imagen-icono-minimenu oscuro-img' />
                    <div className='main-mini-title oscuro-title'>SISTEMA INTEGRAL DE INFORMACIÓN AMBIENTAL - SIIAM</div>
                </div>
                <div className='botton-mini' onClick={() => handleClick('fotografia')}>
                    <img src={IcoFotografia} alt="Imagen 1" className='imagen-icono-minimenu' />
                    <div className='main-mini-title '>Fotografía</div>
                </div>
            </div>
        </div>
    );
}

export default SubMenuMini;
