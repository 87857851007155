import React from 'react';

import DocLinks from '../../UI/docLinks/docLinks';
import BanerTexto from '../../UI/banerTexto/banerTexto';
import ImageSlider from '../../UI/slider-imagenes/imageSlider';
import Book from '../../UI/book/book';


import ximgprueba1 from './img1.jpg'
import ximgprueba2 from './img2.png'
import ximgprueba3 from './img3.jpg'


import imglibro1 from './informe.png'
import imglibro2 from './programa.png'


import './atmosfera.css'
const docts = [
  {
    id: 1,
    title: "Estaciones de medición de contaminantes criterio por municipio, 2021.",
    linkpdf: "https://drive.google.com/file/d/1ez-7dMo_j19bCOsHbPnPSrCAtw5N1_qS/view?usp=sharing"
  },
  {
    id: 1,
    title: "Inventario de emisiones de contaminantes atmosféricos de fuentes fijas por origen de las emisiones, 2016.",
    linkpdf: "https://drive.google.com/file/d/1TNtT3WXYHGuDbdCFIHP_QE-by_DuQ8gR/view?usp=sharing"
  },
  {
    id: 1,
    title: "Inventario de emisiones de contaminantes atmosféricos de fuentes fijas por sector SCIAN, 2016.",
    linkpdf: "https://drive.google.com/file/d/1dp6vaP5rh0A_zXHLgH7JkOBmvPmom4Mo/view?usp=sharing"
  },
  {
    id: 1,
    title: "Máximo de las concentraciones de los promedios móviles de 8 h de ozono.",
    linkpdf: "https://drive.google.com/file/d/1CFX9D9IpS34QYLj0xLBvrHYhk4fIUiLt/view?usp=sharing"
  },
  {
    id: 1,
    title: "Máximo de las concentraciones promedio de 24 h de partículas menores o iguales a 10 micrómetros (PM10).",
    linkpdf: "https://drive.google.com/file/d/1ezwW1KH-hFMMsvnzswjXrSMC5Mm6yn2S/view?usp=sharing"
  },
  {
    id: 1,
    title: "Máximo de las concentraciones promedio de 24h de partículas menores o iguales a 2.5 micrómetros (PM2.5).",
    linkpdf: "https://drive.google.com/file/d/1ff1BMNCDgZCi5q8MOccCpkGtrsEfQ35p/view?usp=sharing"
  },
  {
    id: 1,
    title: "Número de municipios con sequia por entidad federativa y nivel de intensidad.",
    linkpdf: "https://drive.google.com/file/d/1W2_HifK4pL5sYzqPpqZ5xvhEGvWOip4o/view?usp=sharing"
  },
  {
    id: 1,
    title: "Porcentaje de superficie afectada con sequía por entidad federativa y nivel de intensidad.",
    linkpdf: "https://drive.google.com/file/d/1YRU8An7k48r-X7dhJmVkJMUsvE5jpJ8h/view?usp=sharing"
  },
  {
    id: 1,
    title: "Segundo máximo de las concentraciones horarias de dióxido de carbono.",
    linkpdf: "https://drive.google.com/file/d/1Y80fyYQQGccmC8RsHHyAfB7N_9j8EAJ4/view?usp=sharing"
  },
  {
    id: 1,
    title: "Segundo máximo de las concentraciones horarias de dióxido de nitrógeno.",
    linkpdf: "https://drive.google.com/file/d/15U30-ogL3gftsQ3-1I2N8dzn3f8NLuYG/view?usp=sharing"
  },
];

const books = [
  {
    title: 'Libro 1',
    coverImageUrl: imglibro1,
    bookUrl: 'https://drive.google.com/file/d/1_qVzfh--S82cQxIa7DovRC1UgpPV5XsN/view?usp=sharing',
    bookTitle: 'Informe Nacional de la Calidad del Aire en México, 2018.',
    description: '-De acuerdo con la organización mundial de la salud (OMS), la contaminación del aire representa un importante riesgo medioambiental para...',
  },
  {
    title: 'Libro 2',
    coverImageUrl: imglibro2,
    bookUrl: 'https://drive.google.com/file/d/1BLIrEy1E-0EaNNS3XfXGGYH1cb9Jd4aB/view?usp=sharing',
    bookTitle: 'Programa de Gestión para Mejorar la Calidad del Aire en el Estado de Michoacán 2015 - 2024.',
    description: '-La contaminación del aire conlleva graves problemas de salud hacia la población y de afectación a los ecosistemas...',
  },
];

const objetivos = [
  {
    id: 1,
    titulo: "Fuentes Fijas:",
    parrafo: "Los establecimientos que se encuentran estacionarios y tengan como finalidad desarrollar actividades industriales, mercantiles y de servicios que generen o puedan generar emisiones contaminantes al ambiente. LEY PARA LA CONSERVACIÓN Y SUSTENTABILIDAD AMBIENTAL DEL ESTADO DE MICHOACÁN DE OCAMPO . 2021.",
  },
  {
    id: 2,
    titulo: "Fuentes Móviles:",
    parrafo: "Los vehículos automotores que emitan contaminantes al ambiente. LEY PARA LA CONSERVACIÓN Y SUSTENTABILIDAD AMBIENTAL DEL ESTADO DE MICHOACÁN DE OCAMPO. 2021.",
  },
  {
    id: 3,
    titulo: "Fuentes Móviles (carreteras y no carreteras)",
    parrafo: "Se trata de los vehículos con motores de combustión y similares, que debido a su funcionamiento generan o pueden a generar emisiones contaminantes a la atmósfera. Las fuentes móviles carreteras incluyen: tractocamiones, autobuses, camiones, automóviles, motocicletas; mientras que las fuentes móviles no carreteras comprenden: aviones, helicópteros, ferrocarriles, tranvías, embarcaciones, equipo y maquinarias. Fuente: SEMARNAT. 2022.",
  }
];
const imagenes = [
  ximgprueba1,
  ximgprueba2,
  ximgprueba3,
];
function Atmosfera() {
  return (
    <div className='main-atmosfera'>
      <h2 className='subtitulo-main-atmosfera'>Atmósfera</h2>
      <DocLinks title="Index: documentos" Docts={docts} />
      <Book title="Libros" books={books} />
      <BanerTexto textos={objetivos} />
      <ImageSlider title="Fotografías" images={imagenes} />
    </div>
  );
}

export default Atmosfera;
