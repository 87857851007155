import React, { useState } from 'react';
import './Dialog.css';

function Dialog(props) {
  const [show, setShow] = useState(true);

  const handleConfirm = () => {
    setShow(false);
    props.onConfirm();
  };

  return (
    <div className={`dialog ${show ? 'show' : ''}`}>
      <div className="dialog-content">
        <div className="dialog-header">
          <h2>{props.title}</h2>
        </div>
        <div className="dialog-body">
          <p>{props.message}</p>
        </div>
        <div className="dialog-footer">
          <button className="confirm-button" onClick={handleConfirm}>
            Aceptar
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dialog;
