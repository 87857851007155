import React from 'react';
import './book.css'

function Book(props) {
    const { title, books } = props;

    const openBookUrl = (bookUrl) => {
        window.open(bookUrl, '_blank');
    };

    return (
        <div className='main-books'>
            <h2 className="main-books-title">{title}</h2>
            <div className="books">
                {books.map((book, index) => (
                    <div key={index} className="book" >
                        <div className="book-cover" onClick={() => openBookUrl(book.bookUrl)}>
                            <img className='book-img' src={book.coverImageUrl} alt={book.bookTitle} />
                            <div className="book-cover-message">Click para ver el libro</div>
                        </div>
                        <div className="book-details">
                            <div className="book-title">{book.bookTitle}</div>
                            <div className="book-description">{book.description}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    );
}

export default Book;
