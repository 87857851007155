import React from 'react';

import DocLinks from '../../UI/docLinks/docLinks';
import ImageGallery from '../../UI/imageGalery/ImageGallery';
import ImgTexto from '../../UI/img-texto/ImgTexto'

import './agua.css'


import acuiferos from './acuiferos.png'
import cuencas from './cuencas.png'
import regiones from './regiones.png'

import grafica1 from './grafica1.png'
import grafica2 from './grafica2.png'

import marco from './marco.png'


function Agua() {
    const images = [
        acuiferos,
        cuencas,
        regiones,
        // Añade aquí las rutas de todas las imágenes que quieras mostrar
      ];
      const images2 = [
        marco,
        // Añade aquí las rutas de todas las imágenes que quieras mostrar
      ];
    const docts = [
        {
          id: 1,
          title: "Plantas de tratamiento de aguas residuales.",
          linkecxel: "https://docs.google.com/spreadsheets/d/1qhfdAm5aOzYR_izvSI5P1st9g4WpJYgi/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
          linkpdf: "https://drive.google.com/file/d/1x-ezVToQ4iFhbYyWRlOnt9H9uHB5J1On/view?usp=sharing"
        },
      ];
      const docts2 = [
        {
          id: 1,
          title: "Acuíferos por región hidrológica - administrativa.",
          linkpdf: "https://drive.google.com/file/d/1freATmQWbArzc5ZeGAjTjcAZ7yva4FNA/view?usp=sharing"
        },
        {
            id: 1,
            title: "Agua suministrada al día por habitante para consumo humano.",
            linkpdf: "https://drive.google.com/file/d/1eJKrE-y4mYu4MIsT4JioWJbuuKaiHLJs/view?usp=sharing"
        },
        {
            id: 1,
            title: "Capacidad instalada en plantas de tratamiento en operación de agua residual municipal.",
            linkpdf: "https://drive.google.com/file/d/1HhWFfPpWYS7KqZ0MFScwUqbttl-0UdgB/view?usp=sharing"
        },
        {
            id: 1,
            title: "Capacidad instalada total en plantas de tratamiento de agua residual municipal.",
            linkpdf: "https://drive.google.com/file/d/1-dvAPYsTYHfDchlR8KPrh-Vgb3Bzmzec/view?usp=sharing"
        },
        {
            id: 1,
            title: "Cobertura de la población con agua potable.",
            linkpdf: "https://drive.google.com/file/d/10cKt1d2pAkaehP6yEuJvbQzCZFzMWP7f/view?usp=sharing"
        },
        {
            id: 1,
            title: "Escurrimiento superficial virgen medio por entidad federativa.",
            linkpdf: "https://drive.google.com/file/d/1pL-YTD9dDtcj9VXl85jtu9XNWY5ByjDk/view?usp=sharing"
        },
        {
            id: 1,
            title: "Estimación del agua renovable (disponibilidad natural media del agua) por entidad federativa.",
            linkpdf: "https://drive.google.com/file/d/1e_3whVr1cjCh8N6uaJ-Dclovk7sTLhuK/view?usp=sharing"
        },
        {
            id: 1,
            title: "Michoacán volúmenes concesionados por tipo de uso y municipio, según fuente de abastecimiento.",
            linkpdf: "https://drive.google.com/file/d/1H1w2VULI5jGz_wgfGrrNEzZDGpxMqHPb/view?usp=sharing"
        },
        {
            id: 1,
            title: "Municipios y delegaciones con servicios de agua potable, alcantarillado y tratamiento de aguas residuales.",
            linkpdf: "https://drive.google.com/file/d/1dPEOHMviMNkffEavE03ABjpQ1K2jkO-r/view?usp=sharing"
        },
        {
            id: 1,
            title: "Municipios y delegaciones con tratamiento de aguas residuales municipales que reportan la generación y tratamiento de lodos residuales, 2010.",
            linkpdf: "https://drive.google.com/file/d/19pQn7HfwxHDHC_jb6-P8oS_cQCcUQ_16/view?usp=sharing"
        },
        {
            id: 1,
            title: "Plantas de tratamiento de agua residual industrial.",
            linkpdf: "https://drive.google.com/file/d/1p8p3ihtDKpcsR0fJH--SdxEXJmf6pTiu/view?usp=sharing"
        },
        {
            id: 1,
            title: "Plantas de tratamiento en operación, capacidad instalada y gasto tratado de agua residual municipal.",
            linkpdf: "https://drive.google.com/file/d/1Wc8Fm7zz7jJz7OWR5NtH1PStpkRvVzYN/view?usp=sharing"
        },
        {
            id: 1,
            title: "Plantas de tratamiento totales de agua residual municipal.",
            linkpdf: "https://drive.google.com/file/d/1RqWYQ9ysHpKbgXG26yQZ4FiNrAWIzRQW/view?usp=sharing"
        },
        {
            id: 1,
            title: "Plantas potabilizadoras en operación y gasto potabilizado.",
            linkpdf: "https://drive.google.com/file/d/1j8jExGtayJilQgvk7hC2JuTR_VaNZun5/view?usp=sharing"
        },
        {
            id: 1,
            title: "Plantas potabilizadoras según proceso de potabilización por entidad federativa.",
            linkpdf: "https://drive.google.com/file/d/1Q8T7JHdJQ7tnE1wrSSQAf7PHX-TJ4Jff/view?usp=sharing"
        },
        {
            id: 1,
            title: "Plantas potabilizadoras totales por entidad federativa.",
            linkpdf: "https://drive.google.com/file/d/1IKimtU48mc-CpOSsH-ifau5tqUlWNnRy/view?usp=sharing"
        },
        {
            id: 1,
            title: "Población con agua potable.",
            linkpdf: "https://drive.google.com/file/d/1N3xr6ZFU_pvrF_HO-XRUnFgx6z5AhjlC/view?usp=sharing"
        },
        {
            id: 1,
            title: "Precipitación media por entidad federativa (milimetros).",
            linkpdf: "https://drive.google.com/file/d/1pXAnQEZ4oL7EWo4A8U_5Tx5KRbWOn-ev/view?usp=sharing"
        },
        {
            id: 1,
            title: "Presas principales almacenamiento anual respecto de su nivel máximo ordinario.",
            linkpdf: "https://drive.google.com/file/d/1v7uxre-xGz2sD0klc3Q6NQ9CfYWcojpr/view?usp=sharing"
        },
        {
            id: 1,
            title: "Presas principales uso y capacidad de almacenamiento.",
            linkpdf: "https://drive.google.com/file/d/13y0jQC9NMjX_0Bj-sFXG4OqncXbq_CIM/view?usp=sharing"
        },
        {
            id: 1,
            title: "Puntos de descarga de aguas residuales municipales sin tratamiento por tipo de cuerpo receptor.",
            linkpdf: "https://drive.google.com/file/d/1CiLHKbhP_Riv-YTsdxNvNw34JvZWTHGG/view?usp=sharing"
        },
        {
            id: 1,
            title: "Sitios de monitoreo de calidad del agua superficial que miden coliformes fecales, por nivel de calidad del agua.",
            linkpdf: "https://drive.google.com/file/d/1CdbLJrf2Mk0Kkf4tRv6uGz_rFfBNfcEZ/view?usp=sharing"
        },
        {
            id: 1,
            title: "Tomas de agua en operación para abastecimiento público por fuente de extracción.",
            linkpdf: "https://drive.google.com/file/d/1pk73iPlYiM9YciJuoU1yBbcpkCTJHJEU/view?usp=sharing"
        },
        {
            id: 1,
            title: "Volumen tratado en plantas de tratamiento en operación de agua residual municipal.",
            linkpdf: "https://drive.google.com/file/d/1goek8IueGugnxjIp9Aes7krNmP1k2mh0/view?usp=sharing"
        },
        {
            id: 1,
            title: "Volúmenes de extracción y recarga de acuíferos sobreexplotados.",
            linkpdf: "https://drive.google.com/file/d/1AfjLJS3SLxh7iNePObc45sJNpmr159Nj/view?usp=sharing"
        },
      ];
    return (
        <div className='main-agua'>
            <h2 className='subtitulo-main-agua '>Agua</h2>
            <DocLinks title="Index: documentos" Docts={docts} />
            <DocLinks title="Registros federales. Fuente: SEMARNAT" Docts={docts2} />
            <ImgTexto
                imageSrc={grafica1}
                title="Evolución de la cobertura del servicio de agua potable en Michoacán"
                text="En la grafica se observa que la evolución de la cobertura del servicio de agua potable en Michoacán en el 2020 alcanzo el 97.10%."
            />
            <ImgTexto
                imageSrc={grafica2}
                title="Evolución de la cobertura del servicio de alcantarillado en Michoacán"
                text="En la grafica se observa  que la evolución de la cobertura del servicio de alcantarillado en Michoacán para el 2020 fue del 95.50%."
            />
            <ImageGallery title="Mapas" images={images} />
            <ImageGallery title="Marco Conceptual" images={images2} />
        </div>
    );
}

export default Agua;
