import React from 'react';
import './proteccion.css'

import DocLinks from '../../UI/docLinks/docLinks';


function Protección() {
    const docts = [
        {
          id: 1,
          title: "Denuncias ambientales  por materia reguladas y municipio.",
          linkecxel: "https://docs.google.com/spreadsheets/d/1SHwogWFJP4k3j_wHCxY8g3IM8PdpbTcp/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
          linkpdf: "https://drive.google.com/file/d/1fOsIY3jyAtXpqL2dzDwbAJj4u2mlqWj_/view?usp=sharing"
        },
        {
          id: 1,
          title: "Denuncias ambientales por materia reguladas.",
          linkecxel: "https://docs.google.com/spreadsheets/d/1IftIhx0uPOv-LjapT1nNJZj_95YW4S43/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
          linkpdf: "https://drive.google.com/file/d/1vrg_SBjfGQYcgNwtHjp9yIIionFtz9kV/view?usp=sharing"
        },
        {
          id: 1,
          title: "Denuncias ambientales concluidas.",
          linkecxel: "https://docs.google.com/spreadsheets/d/1QN8tBCbEXpgZNvPkX1eLiF2gH-FXpNvA/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
          linkpdf: "https://drive.google.com/file/d/1V1vhgzBN3SXJOFwSrmW7CiJRWgw13hwH/view?usp=sharing"
        },
        {
          id: 1,
          title: "Denuncias ambientales totales.",
          linkecxel: "https://docs.google.com/spreadsheets/d/171FyfqCt5KwenkiSUsKqqu81hMof4CKE/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
          linkpdf: "https://drive.google.com/file/d/1VGnxGYwU0oKXijLfbLJ4Pdd0sWHHXA_i/view?usp=sharing"
        },
        {
          id: 1,
          title: "Registro de denuncias de la procuraduría de protección al ambiente PROAM 2021.",
          linkecxel: "https://docs.google.com/spreadsheets/d/1wM5X3tPBHd7xdm71FdNJ-exXIqIOk4K1/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
          linkpdf: "https://drive.google.com/file/d/17SDuZ2CuVGPlkt8WoD_NdRVLo2v_8cqY/view?usp=sharing"
        },
        {
          id: 1,
          title: "Registro de denuncias de la procuraduría de protección al ambiente PROAM 2022.",
          linkecxel: "https://docs.google.com/spreadsheets/d/1ocdcmuuapAaz1pyv3KO2KsuxWI2Cb45R/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
          linkpdf: "https://drive.google.com/file/d/1nV1YHaAZcQeWYmh4Qb4MXMKsk3A62Vf4/view?usp=sharing"
        },
      ];
    return (
        <div className='main-proteccion'>
            <h2 className='subtitulo-main-proteccion'>Inspección y Vigilancia Ambiental</h2>
            <DocLinks title="Index: documentos" Docts={docts} />
        </div>
    );
}

export default Protección;
