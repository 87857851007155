import React from 'react';
import './Links.css';

const Links = ({ title, links }) => {
    return (
        <div className="links-container">
            <h3 className='links-titulo'>{title}</h3>
            <div className="links-list">
                {links.map((link, index) => (
                    <a key={index} href={link.url} className="link-item" target="_blank" rel="noopener noreferrer">
                        {link.label}
                    </a>
                ))}
            </div>
        </div>
    );
};

export default Links;
