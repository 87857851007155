import React from 'react';
import YouTube from 'react-youtube';
import DocLinks from '../../UI/docLinks/docLinks';
import Book from '../../UI/book/book';

import './suelo.css'

import imglibro1 from './diagnostico.png'
import imglibro2 from './dinamica.png'
import imglibro3 from './erosion.png'
function Suelo() {
    const docts = [
        {
            id: 1,
            title: "Degradación de suelos química.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1vonqSmYQ6ANiKNa_9gk4S9Zzvxd2_Ksl/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1tJh9LHaxZ6xJJSTipaj48fdIdc9mTtzo/view?usp=sharing"
        },
        {
            id: 1,
            title: "Degradación de suelos eólica.",
            linkecxel: "https://docs.google.com/spreadsheets/d/18zPTBgD_y4-6omUzPJjRgN7K5T5-PIaJ/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1gdj4lcOwkRUJy8Jt74OVwEYb-4RKrVMI/view?usp=sharing"
        },
        {
            id: 1,
            title: "Degradación de suelos física.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1EE3TQS3MU-alGAabFY7ffDej_SqLj1Hh/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1OHdkCeMPXYz6gB9Ged3VAeC-FFPx1hiH/view?usp=sharing"
        },
        {
            id: 1,
            title: "Degradación de suelos hídrica.",
            linkecxel: "https://docs.google.com/spreadsheets/d/10VFBmrhWLvlN-Pii7JczB-3RErHMEXwN/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1tHkHGLtB_fQucRraI6TYDBWvHZUMw9l8/view?usp=sharing"
        },
    ];
    const books = [
        {
            title: 'Libro 1',
            coverImageUrl: imglibro1,
            bookUrl: 'https://drive.google.com/file/d/16uomABq-4p-gB-Z5QLISzxljBTiR6g0o/view?usp=sharing',
            bookTitle: 'Diagnóstico sobre la Contaminacion por Plaguicidas en Agua Superficial, Agua Subterranea y Suelo.',
            description: '-Coordinación General de Contaminación y Salud Ambiental.',
        },
        {
            title: 'Libro 2',
            coverImageUrl: imglibro2,
            bookUrl: 'https://drive.google.com/file/d/1IW8V3xvWj43aQpPUGpJ9IkcIRXTxgvJJ/view?usp=sharing',
            bookTitle: 'La dinámica del cambio de uso de suelo en Michoacán.',
            description: '-Una propuesta metodológica para el estudio de los procesos de deforestación.',
        },
        {
            title: 'Libro 2',
            coverImageUrl: imglibro3,
            bookUrl: 'https://drive.google.com/file/d/1iaIepPrSd_vejrKD8jnwAzh50IS8azIA/view?usp=sharing',
            bookTitle: 'Erosión de Suelos en México',
            description: '-La palabra erosión proviene del latín erosio que significa: el desgaste que se produce en la superficie del suelo por la acción...',
        },
    ];
    return (
        <div className='main-suelo'>
            <h2 className='subtitulo-main-suelo'>Suelo</h2>
            <DocLinks title="Index: documentos" Docts={docts} />
            <Book title="Libros" books={books} />
            <div className='biodiversidad-container-videos'>
                <YouTube className='video' videoId="-4Qy5YzHfhM" />
                <YouTube className='video' videoId="1xblzQ7TsKA" />
            </div>

            <div className='biodiversidad-container-videos'>
                <YouTube className='video' videoId="JiULQ0hbsEE" />
            </div>
        </div>
    );
}

export default Suelo;
