import React from 'react';
import Book from '../../UI/book/book';

import './sistema-integral.css'

import imglibro1 from './siiam.png'

import imglibro2 from './entecedentes.png'
function MarcoJuridico() {
    const books = [
        {
            title: 'Libro 1',
            coverImageUrl: imglibro1,
            bookUrl: 'https://docs.google.com/document/d/18b7ZV42XZtPA3NSYMFfaS0bOuUHTOVRT/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true',
            bookTitle: 'Qué es el Sistema Integral de Información Ambiental de Michoacán SIIAM',
            description: '-El Sistema Integral de Información Ambiental de Michoacán (SIIAM), es un instrumento de la Ley para la Conservación y Sustentabilidad Ambiental...',
        },
        {
            title: 'Libro 1',
            coverImageUrl: imglibro2,
            bookUrl: 'https://docs.google.com/document/d/1RRXPUvWL-wdexqKx9rKMIHFsiJ4ArWeV/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true',
            bookTitle: 'ANTECEDENTES Legislación justificativa del SIIAM',
            description: '-Departamento de Sistema de Información Ambiental',
        },
    ];
    return (
        <div className='main-marco'>
            <h2 className='subtitulo-main-marco'>Sistema Integral de Información Ambiental</h2>
            <Book title="Libros:" books={books} />
        </div>
    );
}

export default MarcoJuridico;