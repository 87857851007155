import React from 'react';
import FolderComponent from '../../UI/carpetas/FolderComponent';


import './marcoJuridico.css'

function MarcoJuridico() {
    
    const folders = [
        { 
            title: 'Leyes Internacionales Ambientales', 
            link: 'https://drive.google.com/drive/folders/18qFg1ZTSShzOuExjGqzgzUqPUCUsy3UV?usp=sharing' 
        },
        { 
            title: 'Leyes Generales Ambientales', 
            link: 'https://drive.google.com/drive/folders/1h8vUv62jf7V1qo69SvjitIIy8TgX9U3w?usp=sharing' 
        },
        { 
            title: 'Leyes Federales Ambientales', 
            link: 'https://drive.google.com/drive/folders/1tAZws6Z9WQZhjyZ5OSHqyz3yCOJugDcb?usp=sharing' 
        },
        { 
            title: 'Normas Oficiales Mexicanas Ambientales', 
            link: 'https://drive.google.com/drive/folders/1jvD-okzB8z-wUtU5ZcbQpLXOL1ho3HR9?usp=sharing' 
        },
    
    ];
    return (
        <div className='main-marco'>
            <h2 className='subtitulo-main-marco'>Prontuario Jurídico Ambiental</h2>
            
            <FolderComponent folders={folders} />
        </div>
    );
}

export default MarcoJuridico;