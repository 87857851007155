import React from 'react';
import icoCarpeta from "./carpeta.png"
import "./FolderComponent.css"
const FolderComponent = ({ folders }) => {
  return (
    <div className="folder-container">
      {folders.map((folder, index) => (
        <a key={index} className="folder-item" href={folder.link} target="_blank" rel="noopener noreferrer">
          <img src={icoCarpeta} alt="Folder Icon" />
          <div>{folder.title}</div>
        </a>
      ))}
    </div>
  );
};

export default FolderComponent;
