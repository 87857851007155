import React from 'react';
import './Footer.css';

import mich from '../../logos/MICHOACÁN HORIZONTAL.png'
import horizo from '../../logos/PNG HORIZONTAL.png'
import agua from '../../logos/agua.png'
import forestal from '../../logos/forestal.png'
import proam from '../../logos/proam.png'
import inegi from '../../logos/INEGI_b.png'
import zoo from '../../logos/Icono Zoo-1 (1).png'

function Footer() {
  const fechaActual = new Date().getFullYear();
  return (
    <footer class="footer">
      <div className='row-logos'>

        <div class="col">
          <a href='https://medioambiente.michoacan.gob.mx/' target='blank'>
            <img src={horizo} alt="Imagen 2" />
          </a>
        </div>
        <div class="col">
          <a href='https://cofom.michoacan.gob.mx/' target='blank'>
            <img src={forestal} alt="Imagen 4" />
          </a>
        </div>
        <div class="col">
          <a href='https://ceac.michoacan.gob.mx/' target='blank'>
            <img src={agua} alt="Imagen 3" />
          </a>
        </div>
        <div class="col">
          <a href='https://proam.michoacan.gob.mx/' target='blank'>
            <img src={proam} alt="Imagen 5" />
          </a>
        </div>
        <div class="col">
          <a href='https://zoomorelia.michoacan.gob.mx/' target='blank'>
            <img src={zoo} alt="Imagen 5" />
          </a>
        </div>
        <div class="col">
          <a href='https://www.inegi.org.mx/' target='blank'>
            <img src={inegi} alt="Imagen 6" />
          </a>
        </div>
        <div class="col">
          <a href='https://www.michoacan.gob.mx/' target='blank'>
            <img src={mich} alt="Imagen 1" />
          </a>
        </div>
      </div>

      <div class="footer-content">
        <span>&copy; Derechos Reservados </span>
        <span className='fecha'>{fechaActual}</span>
      </div>
    </footer>
  );
}

export default Footer;
