import React from 'react';
import './infografias.css';
import info1 from './info1.jpeg';
import info2 from './info2.jpeg';
import info3 from './info3.jpeg';
import info4 from './info4.jpeg';
import info6 from './info5.jpg';
import info7 from './Cambio Climático  SEMARNAT.png';
import info5 from './Que es cambio climatico SEMARNT.png';
import info8 from './Semana Mundial del Suelo .jpg';
import info9 from './Servicios Ambientales del Suelo SEMARNAT.png';
import info10 from './Ordenamiento Ecológico Territorial.jpg'
import info11 from './Día Mundial de los Océanos .jpeg'
import info12 from './Día Mundial del Medio Ambiente .jpeg'
import info13 from './El clima cambia SEMARNAT .png'

function Infografias() {
  const dummy_infos = [info1, info2,  info4, info5, info6,info3, info7, info8, info9, info10, info11, info12, info13 ];

  return (
    <div className='main-infografia'>
      <h2 className='subtitulo-main-infografia'>Infografías</h2>
      <div className='infografia-container'>
        {dummy_infos.map((info, index) => (
          <img key={index} src={info} alt={`Infografía ${index + 1}`} />
        ))}
      </div>
    </div>
  );
}

export default Infografias;
