import React from 'react';
import DocLinks from '../../UI/docLinks/docLinks';
import ImgTexto from '../../UI/img-texto/ImgTexto'

import './impacto.css'

import grafica1 from './grafica1.png'

function Impacto() {
    const docts = [
        {
            id: 1,
            title: "Manifestaciones de impacto ambiental de desarrollos telecomunicaciones 2021.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1yKAyaOLxFrgVtVer94ohybFtWhnd2lbc/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1c2aEQ_GHJOni42_gx54zBFJ_FiCMlLu8/view?usp=sharing"
        },
        {
            id: 1,
            title: "Manifestaciones de impacto ambiental de desarrollos telecomunicaciones 2022.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1nYGGq_tCCV62wK4ZAIlnHSW6IackNX7Z/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1MqC29gks5VSjVJdMy4UNOck3qq7WW-us/view?usp=sharing"
        },
        {
            id: 1,
            title: "Manifestaciones de impacto ambiental de desarrollos turisticos 2022.",
            linkecxel: "https://docs.google.com/spreadsheets/d/10Fq_T7-mWkrqUcnv9nHTySjL5ckJR2Hu/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/11EZT9nNnVXIk0WPa64foomX3O7L3-T6B/view?usp=sharing"
        },
        {
            id: 1,
            title: "Manifestaciones de impacto ambiental de industria 2021.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1iusHmP4IIMm8FCITfjuaZ87wgH71zxNE/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1QNgeLh01mBOLS0YGTbFns1B8hiRzNged/view?usp=sharing"
        },
        {
            id: 1,
            title: "Manifestaciones de impacto ambiental de industria 2022.",
            linkecxel: "https://docs.google.com/spreadsheets/d/15M4lyhOgoM-LUq7vzHa2VOmUGPs_m80d/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1YoO_GNHA-5QOAyLjw2U3OzY_bhmsCqFo/view?usp=sharing"
        },
        {
            id: 1,
            title: "Manifestaciones de impacto ambiental de industria 2023.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1KYL86M4v0oEi0sh6-Qek3wQFOj-ooaco/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1ks3oEkkm2HFbJIU4fqQFcsw-HLqAIR4Y/view?usp=sharing"
        },
        {
            id: 1,
            title: "Manifestaciones impacto ambiental hidráulica 2021.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1rMRb32xHxYjOHP__VSmE5Kc-fG9DKTFD/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1INq6K_TTRD_cYGcD5HbdY35O8DcZo3RG/view?usp=sharing"
        },
        {
            id: 1,
            title: "Manifestaciones impacto ambiental hidráulica 2022.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1xqlOhjDox_dR-CvMqtsB--1qWMhF4-AC/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1pXRl4DONDAHjBn-HEIeuTsRFhX-v1iNR/view?usp=sharing"
        },
        {
            id: 1,
            title: "Manifestaciones impacto ambiental hidráulica 2023.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1oSZ_U8OFP45VLalHjlyXqUJEiaXcj2G1/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1wJvQEcKn9TTZZv9VcgCb_Ymbozz6un73/view?usp=sharing"
        },
        {
            id: 1,
            title: "Manifestaciones impacto ambiental de fraccionamientos 2021.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1sU7C08lNheg0ydyC-4WUDm-1zb9lm9Ce/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/15hIItz-0a717dVJY1T77Tf9Za6jrRLe8/view?usp=sharing"
        },
        {
            id: 1,
            title: "Manifestaciones impacto ambiental de fraccionamientos 2022.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1JbsNcKA3q3sBX7BCnJJYoKuSvAEcHe2x/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/14DWlrL5wvkaR_nuCFkpBX1bLA5Tm4FoP/view?usp=sharing"
        },
        {
            id: 1,
            title: "Manifestaciones impacto ambiental de fraccionamientos 2023.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1UduMpfPOv5LCliy1W06eHKwqpwJ_bcfB/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1OofaKYr8dQgUwBTADkxB8Vz0nVxJvp11/view?usp=sharing"
        },
    ];
    return (
        <div className='main-impacto'>
            <h2 className='subtitulo-main-areas'>Impacto y Riesgo Ambiental</h2>
            <DocLinks title="Index: documentos" Docts={docts} />
            <ImgTexto
                imageSrc={grafica1}
                title="Evaluación del Impacto ambiental de obras y actividades en el estado."
                text="Anualmente se evalúan un promedio de 162 obras y actividades de competencia estatal y 30 de competencia federal en el estado (SEMARNAT, 2023). SEMARNAT 2023 y SECMA 2023."
            />
        </div>
    );
}

export default Impacto;
