import React from 'react';

import DocLinks from '../../UI/docLinks/docLinks';

import './zoologico.css'


function Zoologico() {
    const docts = [
        {
            id: 1,
            title: "Listado de ejemplares anfibios.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1Wbk0TQM4uPV34rjIbT6kcM1a5OSPWILK/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1To-otJZEkKw1ABpz3oLaATtKUyULZbpK/view?usp=sharing"
        },
        {
            id: 1,
            title: "Listado de ejemplares de aves domésticas.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1dt_lIzhY_ZbrO-IkF7mJW2CPmQZVjriJ/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1A4TMSMhSjym044ZqIMtxLtftba6RRrpC/view?usp=sharing"
        },
        {
            id: 1,
            title: "Listado de ejemplares de aves silvestre.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1pa0A0v-C-T6PXGtMVHjr14S4fUZn5qwO/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1MsjDBozG_t6QcrIwBU1wT-RRJ-6mDf-R/view?usp=sharing"
        },
        {
            id: 1,
            title: "Listado de ejemplares de peces.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1V5CvTeVCoyv7j6UrsAZulSHriUhM3HdC/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1TQ3Av3mkvVZygANbEMNDaQEZ_hAEeSHK/view?usp=sharing"
        },
        {
            id: 1,
            title: "Listado de ejemplares de reptiles.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1bM8uZrjKqislPjjVPpdgk0T2Pd6nZ0tR/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1klmx5VjDMWAWsvGh0LFOleec1-Ez1NuW/view?usp=sharing"
        },
        {
            id: 1,
            title: "Listado de ejemplares mamíeros silvestres.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1ZQIeIk5sVVsXOfry0dT_yUtEaZcaYqdk/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/122MJafTTJ6eTpcVMhyRyRMraNqLhxkTq/view?usp=sharing"
        },
        {
            id: 1,
            title: "Listado de ejemplares mamíferos domésticos.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1HWGeijAJPuwLzz6uc-NLXQoKi3iUaPh4/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1NIlQcXWTGAy01FwFgax2spEzoMyrTwew/view?usp=sharing"
        },
        {
            id: 1,
            title: "Listado taxonómico vegetal.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1xPnymIw7MrZ9pKqhDU9jQSrXO8xeUJ0i/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1YNoL-zkOqoA-nKh2-gQH8Od6XpymPxJA/view?usp=sharing"
        },
    ];
    return (
        <div className='main-suelo'>
            <h2 className='subtitulo-main-suelo'>Zoológico</h2>
            <DocLinks title="Index: documentos" Docts={docts} />
        </div>
    );
}

export default Zoologico;
