import React, { useState } from 'react';
import './imageSlider.css';

function ImageSlider(props) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const {title} = props;
    function handleNextClick() {
        setCurrentIndex((currentIndex + 1) % props.images.length);
    }

    function handlePrevClick() {
        setCurrentIndex((currentIndex + props.images.length - 1) % props.images.length);
    }

    return (

        <div className='slider'>
            <h2 className="image-slider-title">{title}</h2>
            <div className='container-images' style={{ position: 'relative', width: '80%', height: '30rem' }}>
                {props.images.map((image, index) => {
                    const style = {
                        backgroundImage: `url(${image})`,
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        backgroundSize: 'cover',
                        left: 0,
                        top: 0,
                        opacity: index === currentIndex ? 1 : 0,
                        transition: 'opacity 0.5s ease-in-out',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '1rem',
                        boxShadow: '2px 2px 4px var(--negro)',
                        backgroundPosition: 'center',
                    };

                    return <div key={index} style={style}></div>;
                })}
            </div>
            <div className="container">
                <button className="button" onClick={handlePrevClick}>Anterior</button>
                <button className="button" onClick={handleNextClick}>Siguiente</button>
            </div>
        </div>
    );
}

export default ImageSlider;

