import React from 'react';
import './fotografia.css'
import ImageSlider from '../../UI/slider-imagenes/imageSlider'
import FolderComponent from '../../UI/carpetas/FolderComponent';

import ximgprueba from '../../main/img/Azufres-2.jpg'
import ximgprueba2 from '../../main/img/Janitzio-24.jpg'
import ximgprueba3 from '../../main/img/Pichilinguillo-5.jpg'
import ximgprueba4 from '../../main/img/Tacambaro.jpg'
import ximgprueba5 from '../../main/img/Uruapan-72.jpg'
import ximgprueba6 from '../../main/img/Zita¦ücuaro-13.jpg'

function Fotografia() {
    const ximages = [
        ximgprueba,
        ximgprueba2,
        ximgprueba3,
        ximgprueba4,
        ximgprueba5,
        ximgprueba6,
        // Añade aquí las rutas de todas las imágenes que quieras mostrar
    ];
    const folders = [
        { title: 'Fotos primer corte mayo 2023', link: 'https://drive.google.com/drive/folders/1ZUScB6O1-rbmHJzmKrIuajOqeSoKg-ew?usp=sharing' },
    ];
    return (
        <div className='main-mapas'>
            <h2 className='subtitulo-main-mapas'>Fotografia</h2>
            <ImageSlider title="Fotografías" images={ximages} />

            <FolderComponent folders={folders} />
        </div>
    );
}

export default Fotografia;