import React from 'react';


import './Enlace.css';


const Enlace = ({ imagenSrc, link }) => {
    const abrirEnlace = () => {
        window.open(link, '_blank'); // Abre el enlace en una nueva pestaña
    };

    return (
        <div className="enlaces">
            <h3>Enlaces:</h3>
            <a href={link} target="_blank" rel="noopener noreferrer">
                <img src={imagenSrc} alt="Imagen del enlace" />
                <div className='enlaces-link'>{link}</div>
            </a>
        </div>
    );
};

export default Enlace;
