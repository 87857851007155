import React from 'react';
import './NotFound.css';

function NotFound() {
  return (
    <div className='notfound-container'>
      <h2 className='notfound-title'>Página no encontrada</h2>
      <p className='notfound-desc'>Lo sentimos, la página que estás buscando no existe.</p>
    </div>
  );
}

export default NotFound;