import React, { useState } from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // Asegúrate de importar BrowserRouter como Router


import Header from './components/header/header.js';
import Main from './components/main/main.js';
import Footer from './components/footer/Footer';
import './App.css';
import { MenuContext } from './MenuContext.js';
import NotFound from './components/notfoud/NotFound.js';
import Dialog from './components/UI/ventanaEmergente/Dialog.js';

function App() {
  const [showDialog, setShowDialog] = useState(true);

  const handleDialogConfirm = () => {
    setShowDialog(false);
  };
  const mensaje = `GOBIERNO DEL ESTADO DE MICHOACÁN
  SECRETARÍA DEL MEDIO AMBIENTE
  
  Dirección de Regulación Ambiental
  
  Este sistema fue diseñado por el Gobierno de Michoacán a través de la Secretaría del Medio Ambiente, con la colaboración del Instituto Nacional de Estadística y Geografía, la Comisión Forestal, Comisión Estatal del Agua y Gestión de Cuencas, Procuraduría de Protección al Ambiente y  el Zoológico de Morelia. 
  Tiene la finalidad de poner a disposición de la ciudadanía la información ambiental del estado de Michoacán, a través de los entes de gobierno ya señalados, así como informar la situación que guarda el medio ambiente en el territorio del estado. Además, busca ser un referente para las autoridades de los diferentes niveles de gobierno para el diseño de políticas públicas y toma de decisiones relacionadas con el medio ambiente.
  El sitio fue liberado en el mes de Agosto de 2023 en su versión única, se encuentra en permanente actualización y mejora por parte de la Secretaría del Medio Ambiente. 
  Esperamos te sea de utilidad y te invitamos a que lo mejores con tus comentarios, mismos que puedes hacer llegar a través del correo electrónico drambiental@michoacan.gob.mx
  
  Términos y condiciones del uso del sitio de gob.mx
  La utilización de este sitio y de cualquiera de las aplicaciones del mismo constituye el pleno y expreso consentimiento por parte del usuario para observar y sujetarse respecto de cada uno de los términos y condiciones que aquí se contienen, así como respecto de las políticas de privacidad, políticas de seguridad y, en su caso, cualesquiera otros documentos que conformen parte o regulen la participación del usuario en este sitio. En el supuesto que cualquiera de los términos a los que habrá de sujetarse el usuario cuando acceda a este sitio sean contrarios a sus intereses, deberá abstenerse de hacer uso de este.
  
  Obligaciones del usuario
  Al utilizar este sitio me obligo a cumplir con los términos y condiciones de su uso, establecidos en el presente documento (en adelante, los “términos y condiciones"), por lo que reconozco haber leído, entendido y estar de acuerdo en sujetarme a sus términos y condiciones. De manera que, al acceder al sitio, estoy de acuerdo en acatar lo anterior.
  Por el hecho de hacer uso de la información y hacer uso de los Servicios, el usuario expresa su aceptación y está de acuerdo con los términos y condiciones del uso del sitio, así como con las modificaciones que, en su caso, se realicen.
  Los usuarios no residentes en el Estado de Michoacán que deseen acceder al sitio deberán asegurarse de que el acceso y uso de este, de su contenido y/o de los servicios, les esté permitido de conformidad con su propia legislación.
  El usuario que utilice este sitio fuera de la República Mexicana se hará responsable del cumplimiento de todas las leyes de dicha jurisdicción.
  El uso del sitio es bajo la exclusiva responsabilidad del usuario.
  Se autoriza al Usuario a visualizar y descargar los materiales contenidos en el sitio solamente para su uso personal y no para un uso comercial.
  El Usuario deberá conservar en todas las copias de los materiales descargados, todos los avisos sobre derechos de autor y propiedad intelectual de los materiales, contenidos en los mismos;
  El Usuario no deberá modificar, reproducir o mostrar pública o comercialmente los materiales, ni podrá distribuir o utilizarlos con algún propósito público o comercial;
  El Usuario no deberá transferir los materiales a ningún tercero.
  Nos reservamos el derecho de suspender, interrumpir o dejar de operar el sitio y los servicios, en cualquier momento.  
  `;

  // Divide el mensaje en un arreglo de líneas
  const lineas = mensaje.split('\n');

  // Genera un arreglo de JSX para mostrar cada línea en el diálogo
  const mensajeJSX = lineas.map((linea, index) => <p key={index}>{linea}</p>);


  return (
    <div>
        <Header/>
        <Routes>
          <Route path="/" element={<Navigate to="/principal" />} />
          <Route path="/principal" element={<Main content='main' />} />
          <Route path="/areas-conservacion" element={<Main content='areas-conservacion' />} />
          <Route path="/atmosfera" element={<Main content='atmosfera' />} />
          <Route path="/biodiversidad" element={<Main content='biodiversidad' />} />
          <Route path="/recursos-forestales" element={<Main content='recursos-forestales' />} />
          <Route path="/residuos" element={<Main content='residuos' />} />
          <Route path="/educacion" element={<Main content='educacion' />} />
          <Route path="/suelo" element={<Main content='suelo' />} />
          <Route path="/impacto" element={<Main content='impacto' />} />
          <Route path="/bancos" element={<Main content='bancos' />} />
          <Route path="/agua" element={<Main content='agua' />} />
          <Route path="/cambio" element={<Main content='cambio' />} />
          <Route path="/proteccion" element={<Main content='proteccion' />} />
          <Route path="/ordenamiento" element={<Main content='planeacion' />} />
          <Route path="/contacto" element={<Main content='contacto' />} />
          <Route path="/marco-juridico" element={<Main content='marco-juridico' />} />
          <Route path="/infografias" element={<Main content='infografias' />} />
          <Route path="/demografia" element={<Main content='demografia' />} />
          <Route path="/mapas" element={<Main content='mapas' />} />
          <Route path="/zoologico" element={<Main content='zoologico' />} />
          <Route path="/instrumentos" element={<Main content='instrumentos' />} />
          <Route path="/fotografia" element={<Main content='fotografia' />} />
          <Route path="/sistema-integral" element={<Main content='sistema-integral' />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        {showDialog && (
          <Dialog
            title="Importante!"
            message={mensajeJSX}
            onConfirm={handleDialogConfirm}
          />
        )}
    </div>

  );
}

export default App;
