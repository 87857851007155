import React from 'react';
import Book from '../../UI/book/book';
import ImgTexto from '../../UI/img-texto/ImgTexto'

import './recursos-forestales.css'

import grafica1 from './grafica1.png'
import grafica2 from './grafica2.png'
import imglibro1 from './forest.png'
function RecursoForestal() {
    const books = [
        {
            title: 'Libro 1',
            coverImageUrl: imglibro1,
            bookUrl: 'https://drive.google.com/file/d/1RLLHy-U4ECOo9Ag_CPEBecWNN3DrHmFc/view?usp=sharing',
            bookTitle: 'Inventario Estatal Forestal y de Suelos de Michoacán 2014.',
            description: '-La importancia que tienen los recursos forestales para la generación de bienes y servicios a la sociedad, así como la gran cantidad...',
        },
    ];
    return (
        <div className='main-forestal'>
            <h2 className='subtitulo-main-forestal'>Recursos Forestales</h2>
            <Book title="Libro" books={books} />
            <ImgTexto
                imageSrc={grafica1}
                title="Planta forestal entregada por la COFOM para programas de restauración de suelos y ecosistemas, plantaciones forestales comerciales y sistemas agroforestales y silvopastoriles 2015-2022. Fuente: COFOM,. 2023."
                text="Hasta el 2020 la pérdida de bosques en Michoacán presentó una tasa anual de deforestación con un valor de -1.30%, por lo que es necesario disminuirla a menos de la mitad para alcanzar el Indicador Deforestación Bruta (hectárea/año) del Plan de Desarrollo Integral del Estado de Michoacán (PLADIEM) 2021-2027 que es de -0.80%."
            />
            <ImgTexto
                imageSrc={grafica2}
                title="Número de incendios y superficie total afectada 2013-2022"
                text="En cuanto al numero de incendios  y superficie afectada se observa en la grafica que el año de mayor número de incendios y superficie afectada fue en el 2021 con 634 incendios y una superficie afectada de 34,107 hectáreas.  Y el año con menor número de incendios fue el 2013 con 225 incendios. Fuente: CONAFOR, 2023."
            />
        </div>
    );
}

export default RecursoForestal;