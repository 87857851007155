import React from 'react';
import './FloatingButton.css'


function FloatingButton() {
    return (
        <div className="floating-button">
            <a href="https://docs.google.com/spreadsheets/d/1-MRo6WT1aN8ZRhYB9myQ7E7weAZw7FqI/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer">
                Efemérides Ambientales
            </a>
        </div>
    );
}

export default FloatingButton;
