import React from 'react';
import './banerTexto.css'

function BanerTexto(props) {
    return (
        <div className='main-baner-texto'>
            {props.textos.map((texto) => (
                <div key={texto.id}>
                    <h2 className='titulo-baner-texto'>{texto.titulo}</h2>
                    <p className='parrafo-baner-texto'>{texto.parrafo}</p>
                </div>
            ))}
        </div>
    );
}


export default BanerTexto;
