import React from 'react';
import './contacto.css';
function Header(props) {
  return (
    <div className='main-contacto'>
      <div className='titulo'>Contacto</div>
      <div className='subtitulo'>Domicilio:</div>
      <div className='parrafo'>Escarcha 272, Col. Prados del Campestre, CP 58297, Morelia, Mich. </div>
      <div className='subtitulo'>Correo electrónico:</div>
      <div className='parrafo'>dsambiental.secma@michoacan.gob.mx</div>
      <div className='subtitulo'>Teléfonos:</div>
      <div className='parrafo'>(443) 324 84 00 y (443) 314 00 44 </div>
      <iframe 
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.761549577205!2d-101.1671101!3d19.6801759!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0df72ec82261%3A0x344c4881951a7b4f!2sEscarcha%20272%2C%20Prados%20del%20Campestre%2C%2058297%20Morelia%2C%20Mich.!5e0!3m2!1ses!2smx!4v1681264235625!5m2!1ses!2smx" 
      style={{ border: "0"}} 
      allowfullscreen="" 
      loading="lazy" 
      referrerpolicy="no-referrer-when-downgrade" className='mapa' title='mapa contacto'></iframe>
    </div>
  );
}

export default Header;
