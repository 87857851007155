import React from 'react';
import { useNavigate } from 'react-router-dom';
import './header.css';


import logoSeiarn from './SIIAM Horiz Guinda.png'
import logoMich from '../../logos/MICHOACÁN HORIZONTAL.png'
import logoSecma from '../../logos/PNG HORIZONTAL.png'

function Header() {
  const navigate = useNavigate();

    const handleClick = (content) => {
        window.scrollTo(0, 0);
        navigate(`/${content}`);
    };

  return (


    <header class="header">

      <div class="header-submenu"  >
        <div class="logo ">
          <img src={logoSecma} alt="Logo" />
        </div>
        <div class="logo ">
          <img src={logoMich} alt="Logo" />
        </div>
        <div class="logo-show" onClick={() => handleClick('principal')}>
          <img src={logoSeiarn} alt="Logo" />
        </div>
      </div>
      <div class="header-submenu">
        <div class="contenido ">
          CONTENIDO
          <div class="menu-vertical">
            <div onClick={() => handleClick('main')} class="item-menu">- Inicio -</div>

            <div onClick={() => handleClick('biodiversidad')} class="item-menu">Biodiversidad</div>
            <div onClick={() => handleClick('areas-conservacion')} class="item-menu">Áreas Naturales Protegidas</div>
            <div onClick={() => handleClick('recursos-forestales')} class="item-menu">Recursos Forestales</div>
            <div onClick={() => handleClick('residuos')} class="item-menu">Residuos</div>
            <div onClick={() => handleClick('atmosfera')} class="item-menu">Atmósfera</div>
            <div onClick={() => handleClick('impacto')} class="item-menu">Impacto y Riesgo Ambiental</div>
            <div onClick={() => handleClick('bancos')} class="item-menu">Bancos Pétreos</div>
            <div onClick={() => handleClick('ordenamiento')} class="item-menu">Ordenamiento Ecologico Territorial</div>
            <div onClick={() => handleClick('educacion')} class="item-menu">Educación Ambiental</div>
            <div onClick={() => handleClick('suelo')} class="item-menu">Suelo</div>
            <div onClick={() => handleClick('agua')} class="item-menu">Agua</div>
            <div onClick={() => handleClick('cambio')} class="item-menu">Cambio Climático</div>
            <div onClick={() => handleClick('proteccion')} class="item-menu">Procuraduría de Protección al Ambiente</div>
            <div onClick={() => handleClick('zoologico')} class="item-menu">Zoológico</div>
            {/*<div onClick={() => handleClick('instrumentos')} class="item-menu">Proyectos e Instrumentos Económicos</div>*/}
            <div onClick={() => handleClick('demografia')} class="item-menu">Demografía</div>
            <div onClick={() => handleClick('infografias')} class="item-menu">Infografías</div>

            <div onClick={() => handleClick('marco-juridico')} class="item-menu"><li>Prontuario Jurídico Ambiental</li></div>
            <div onClick={() => handleClick('sistema-integral')} class="item-menu"><li>SIIAM</li></div>
            <div onClick={() => handleClick('fotografia')} class="item-menu"><li>Fotografías</li></div>
            {/*<div onClick={() => handleClick('mapas')} class="item-menu"><li>Mapas y algo más</li></div>
            */}
            <div onClick={() => handleClick('contacto')} class="item-menu">Contacto</div>


          </div>
        </div>
        <div onClick={() => handleClick('contacto')} className='contacto'>CONTACTO</div>

      </div>
    </header>
  );
}

export default Header;

