import React from 'react';
import './main.css';

import CuadroDoc from '../UI/cuadro-documentos/CuadroDoc';

import AreasConservacion from '../content/areas-conservacion/areas-conservacion';
import Atmosfera from '../content/atmosfera/atmosfera';
import Biodiversidad from '../content/biodiversidad/biodiversidad';
import RecursoForestal from '../content/recursos-forestales/recursos-forestales';
import Residuos from '../content/residuos/residuos';
import Educacion from '../content/educacion/educacion';
import Suelo from '../content/suelo/suelo';
import Agua from '../content/agua/agua';
import Cambio from '../content/cambio/cambio';
import Impacto from '../content/impacto/impacto';
import Bancos from '../content/bancos/bancos';
import Proteccion from '../content/proteccion/proteccion';
import Planeacion from '../content/bitacora-ordena/planeacion';
import Demografia from '../content/demografia/demografia';
import MarcoJuridico from '../content/marco-juridico/marcoJuridico';
import Infografias from '../content/infografias/infografias';
import Mapas from '../content/mapas/mapas';
import Zoologico from '../content/zoologico/zoologico';
import Instrumentos from '../content/instrumentos/instrumentos';
import Fotografia from '../content/fotografia/fotografia';
import SistemaIntegral from '../content/sistema-integral/sistema-integral'

import MenuContextual from '../UI/menu-contextual/menu-contextual';
import Enlaces from './enlaces/enlaces';
import Contacto from '../contacto/contacto';
import BanerTexto from '../UI/banerTexto/banerTexto';
import SubMenuMini from './sub-menu-mini/subMenuMini';
import FloatingButton from '../UI/FloatingButton/FloatingButton';

import mapa from './vid-map.mkv';

import imglibro1 from './informacion.png'
import imglibro2 from './internet.png'

function Main(props) {
  const objetivos = [
    {
      id: 1,
      titulo: "Objetivo General:",
      parrafo: "El Sistema Integral de Información Ambiental de Michoacán (SIIAM) impulsado por la Secretaría del Medio Ambiente (SECMA) constituye un mecanismo de difusión de la información ambiental  del estado, relativa a los inventarios de recursos naturales existentes; de las áreas naturales protegidas; del ordenamiento ecológico del territorio; de  la inspección y vigilancia ambiental; del monitoreo y control de la calidad del aire y agua; del registro de emisiones y transferencia de contaminantes; de la gestión integral de residuos; de la contribución a disminuir el cambio climático global; de la promoción del desarrollo sustentable; así como los programas y acciones relevantes que realice la Secretaría a favor de la protección y preservación del ambiente del estado de Michoacán. Con esto se da cumplimiento a lo que establecen las leyes estatales de Conservación y Sustentabilidad Ambiental; de la Prevención y Gestión Integral de Residuos; de Cambio Climático;  de Desarrollo Forestal Sustentable, y de Aguas y Gestión de Cuencas.",
    },
    {
      id: 2,
      titulo: "Objetivos Especificos:",
      parrafo: (
        <ul>
          <li>Registrar, organizar, actualizar  y difundir información estadística y geográfica de los diversos temas relacionados con el ambiente, con la finalidad de garantizar  el derecho universal de los ciudadanos al acceso a la información ambiental.</li>
          <li>Promover y difundir información sobre la situación del medio ambiente en el estado que contribuya  a generar una cultura ambiental.</li>
          <li>Transparentar la situación ambiental del estado, mediante una plataforma virtual, de amplia cobertura y fácil acceso.</li>
          <li>Brindar información ambiental a escala municipal, con la finalidad  de promover y apoyar la implementación de políticas públicas, planes, programas e instrumentos de gestión ambiental  a nivel local.</li>
          <li>Poner a disposición de los medios de comunicación, y de la ciudadanía en general, la información del sector ambiental, que coadyuve a la difusión de la importancia del cuidado del medio ambiente.</li>
          <li>Informar oportunamente a la población sobre eventos relacionados con el ambiente, que puedan significar riesgos para la población.</li>
          <li>Proporcionar información cartográfica, a través de un atlas digital, que sintetice, ubique y facilite la consulta de información ambiental.</li>
          <li>Concentrar la información de las dependencias y entidades del sector ambiental,  académico,  así como de los diferentes niveles de gobierno, en un sistema integral, único, que concentre y facilite la consulta de la ciudadanía. </li>
          <li>Apegarse a los principios de accesibilidad, transparencia, objetividad e independencia en la información del sistema.</li>

        </ul>
      ),
    }
  ];

  const books = [
    {
      title: 'Libro 1',
      coverImageUrl: imglibro1,
      bookUrl: 'https://drive.google.com/file/d/1mtnXbCzzHb_G54Z1Nk_Fy2WgJGqM_5_B/view?usp=sharing',
    },
    {
      title: 'Libro 1',
      coverImageUrl: imglibro2,
      bookUrl: 'https://drive.google.com/file/d/1VrytkQ8fZ0WEwDU1PcdOTRCvrgpnG_WJ/view?usp=drive_link',
    },
  ];
  switch (props.content) {
    case 'areas-conservacion':
      return <AreasConservacion />;
    case 'atmosfera':
      return <Atmosfera />;
    case 'biodiversidad':
      return <Biodiversidad />;
    case 'recursos-forestales':
      return <RecursoForestal />;
    case 'residuos':
      return <Residuos />;
    case 'educacion':
      return <Educacion />;
    case 'suelo':
      return <Suelo />;
    case 'impacto':
      return <Impacto />;
    case 'bancos':
      return <Bancos />;
    case 'agua':
      return <Agua />;
    case 'cambio':
      return <Cambio />;
    case 'proteccion':
      return <Proteccion />;
    case 'planeacion':
      return <Planeacion />;
    case 'contacto':
      return <Contacto />;
    case 'marco-juridico':
      return <MarcoJuridico />;
    case 'infografias':
      return <Infografias />;
    case 'demografia':
      return <Demografia />;
    case 'mapas':
      return <Mapas />;
    case 'zoologico':
      return <Zoologico />;
    case 'instrumentos':
      return <Instrumentos />;
    case 'fotografia':
      return <Fotografia />;
    case 'sistema-integral':
      return <SistemaIntegral />;
    default:
      return (
        <div className='principal'>
          <FloatingButton />
          <h1 className='titulo-main'>SISTEMA INTEGRAL DE INFORMACIÓN AMBIENTAL DE MICHOACÁN</h1>
          <h2 className='subtitulo-main'>GOBIERNO DE MICHOACÁN</h2>
          <div className='main-mapa-container'>
            <div className='main-mapa-info'>
              <div className='main-mapa-titulo'>ATLAS DIGITAL DE INFORMACIÓN AMBIENTAL</div>
              <div className='main-mapa-subtitulo'>Aquí podrás consultar una serie de mapas temáticos con información geográfica sobre el medio ambiente del Estado de Michoacán.</div>
            </div>
            <a className='main-mapa-espace' href="http://mapadigital.secma.michoacan.gob.mx/atlas" target="_blank" rel="noopener noreferrer">
              <div class="contenedor-img">
                <video className='mapa-prueba' autoPlay loop muted src={mapa} alt="Imagen 1" title="Click para ver mapa" />
                <div class="texto-img">Click para abrir el mapa</div>
              </div>
            </a>
          </div>
          <MenuContextual setContent={props.setContent} />
          <BanerTexto textos={objetivos} />
          <SubMenuMini setContent={props.setContent} />

          <Enlaces />
          <CuadroDoc title="Avisos para el usuario" books={books} />
        </div>
      );
  }
}

export default Main;
