import React from 'react';
import DocLinks from '../../UI/docLinks/docLinks';
import Book from '../../UI/book/book';
import BanerTexto from '../../UI/banerTexto/banerTexto';
import ImageGallery from '../../UI/imageGalery/ImageGallery';
import ImgTexto from '../../UI/img-texto/ImgTexto'

import './residuos.css'

import imglibro1 from './book1.png'

import cinta from './cinta.jpg'

import grafica1 from './grafica1.png'
import grafica2 from './grafica2.png'
import grafica3 from './grafica3.png'

function Residuos() {
  const docts = [
    {
      id: 1,
      title: "Generación de Residuos por Municipios.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1Hp1MdmgOK-4uI9inalyqDfdqc6fCMsCG/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1uJhd28FwpAEAwChGzipqyKtAwL0PlpAf/view?usp=sharing"
    },
    {
      id: 1,
      title: "Padrón de Gestores Residuos de Manejo Especial concentrado.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1uCTWK37fH2rokHU-eM2psQ0oF01HhMPj/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1-FCbCs1IxKGAPL0EwSZ079mMrurvDTCr/view?usp=sharing"
    },
    {
      id: 1,
      title: "Padrón de Gestores Residuos de Manejo Especial 2011.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1jntSX3oku4n-XUiygcnDvYGmlZNjwDI5/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1lqvh-uKzJFjQz_DFPfOVQPHWkg7m4VAs/view?usp=sharing"
    },
    {
      id: 1,
      title: "Padrón de Gestores Residuos de Manejo Especial 2012.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1lRKSUa5CmK32wA6-Y7AiDocgP5g-45jN/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1orDwyKQkZFOuwQnJMbtXGumV8Qi8WKdd/view?usp=sharing"
    },
    {
      id: 1,
      title: "Padrón de Gestores Residuos de Manejo Especial 2013.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1ZFaMAQVQqFGhf8I5iGz-fB43l2-J5ERt/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1aZCqOTdqBH_bCLu1o8mcmQgt37BuQ3iq/view?usp=sharing"
    },
    {
      id: 1,
      title: "Padrón de Gestores Residuos de Manejo Especial 2014.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1BrBgDyktQ_RvxPxshNV8HdSyVls47R7K/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1uCmEJX79u09l3Jx-jtnHTEqgFbh8J0Z9/view?usp=sharing"
    },
    {
      id: 1,
      title: "Padrón de Gestores Residuos de Manejo Especial 2015.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1rmDOdv-RDRb9UsXQQ7arD5RSkk6oj5QZ/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1BBpIJJ9xZ2GjuY1MNKwH8LNxp2Drl05n/view?usp=sharing"
    },
    {
      id: 1,
      title: "Padrón de Gestores Residuos de Manejo Especial 2016.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1IORpte8mo83g_x8Aqo0VSRB7Ys21gMN1/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/18_6uOZM2TfeNZ1tgT8u3vzItjoHLi9KD/view?usp=sharing"
    },
    {
      id: 1,
      title: "Padrón de Gestores Residuos de Manejo Especial 2017.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1075X8pNEmKe1yukqe2g_wOMZKGp1Fd2e/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1Tf6Fwjjva1mxGnxqQ-CMsEFRinhhH1ti/view?usp=sharing"
    },
    {
      id: 1,
      title: "Padrón de Gestores Residuos de Manejo Especial 2018.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1nf_clBg14ixcH4V1-Ry1Li5QKe8Nt1EU/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1v6qRnMODc2fU-Dx5o2FgZdRSJnKH7aHc/view?usp=sharing"
    },
    {
      id: 1,
      title: "Padrón de Gestores Residuos de Manejo Especial 2019.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1A8i76w1tj2nR1fnlhrqK0Yt0dmRNf1Vx/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1gQLDmzFhCDmO-gaox6hGsC2HaKHoF-fF/view?usp=sharing"
    },
    {
      id: 1,
      title: "Padrón de Gestores Residuos de Manejo Especial 2020.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1KGKLJMeUcX3OeJDi19pG52odfwTcNVQ6/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1h9QLxCPmSS1FU-P5ZxHXMlC1b7Hg7zAB/view?usp=sharing"
    },
    {
      id: 1,
      title: "Padrón de Gestores Residuos de Manejo Especial 2021.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1_J_e_998LDsdPVr8SPnEtpTQJq_ySqTG/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1mUeB24Z5GtvuZuiiId7P1LmM5VvAXNIT/view?usp=sharing"
    },
    {
      id: 1,
      title: "Padrón de Gestores Residuos de Manejo Especial 2022.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1gpM7FHOtkQcf76SWtrqUTyCCrPl90ccz/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/143C3EJZUryHJh3tWF4o-mX9kvDyFElgh/view?usp=sharing"
    },
    {
      id: 1,
      title: "Padrón Gestores Residuos Manejo Especial 2010.",
      linkecxel: "https://docs.google.com/spreadsheets/d/120moRDKhLG61-Q1r_upwv8aaCT9eNn9h/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1FVie82mq9SSFy88vZvZqPXm3cj128n9U/view?usp=sharing"
    },
    {
      id: 1,
      title: "Sitios Contaminados.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1TQNA0J0RvaJmAyl9BNZiyJ6Eo11t6qDO/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1zvomXOLXw1v086dLdxsYBHVHOQonVkqy/view?usp=sharing"
    },
    {
      id: 1,
      title: "Sitios de Disposción Final por Municipio.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1TOH2Cfvfz_lJPaj3k236CD9o_dI0tk7F/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1ifBzdCM_hsHbb-GRi2RANHwVw0-lISKv/view?usp=sharing"
    },
    {
      id: 1,
      title: "Programa Estatal para la Prevención y Gestión Integral de los Residuos en Michoacán de Ocampo",
      linkpdf: "https://drive.google.com/file/d/1OCM0XUS-OZSqPAAco7fyIwz1_iPO1l8o/view?usp=sharing"
    },
  ];
  const doctsx = [
    {
      id: 1,
      title: "Sitios contaminados por emergencias ambientales según causas de contaminación, competencia de la ASEA.",
      linkpdf: "https://drive.google.com/file/d/1SCRm87NPoEQzk8sx7lv4av6SyQtSM6qs/view?usp=sharing"
    },
    {
      id: 1,
      title: "Sitios contaminados por emergencias ambientales según responsable, competencia de la ASEA.",
      linkpdf: "https://drive.google.com/file/d/1X9Ht_sXlN_VmcYk0QRjASfAHwjUYmsaR/view?usp=sharing"
    },
    {
      id: 1,
      title: "Sitios contaminados por emergencias ambientales según tipo de contaminante, competencia de la ASEA.",
      linkpdf: "https://drive.google.com/file/d/1ucbBT1PwORBoOSst3_G-cjye4FMAwTxL/view?usp=sharing"
    },
    {
      id: 1,
      title: "Sitios contaminados por emergencias ambientales, según causas de contaminación.",
      linkpdf: "https://drive.google.com/file/d/1RYi842QvuGgzCbd7ScnJFdiXjVS9VPR5/view?usp=sharing"
    },
    {
      id: 1,
      title: "Sitios contaminados por emergencias ambientales, según responsables involucrados.",
      linkpdf: "https://drive.google.com/file/d/1pnlcgVRV-SFVII5AebCB460uZBfdlgS8/view?usp=sharing"
    },
    {
      id: 1,
      title: "Sitios contaminados por emergencias ambientales, según tipo de contaminante.",
      linkpdf: "https://drive.google.com/file/d/1kR-QdGWe2dbXSX6_hGJb3C9YvGHL0G0d/view?usp=sharing"
    },
    {
      id: 1,
      title: "Sitios contaminados remediados según responsable competencia de la ASEA.",
      linkpdf: "https://drive.google.com/file/d/1uL0Et09gKDFmoce6mwODwbTLdvxZfOAW/view?usp=sharing"
    },
    {
      id: 1,
      title: "Sitios contaminados remediados según tipo de contaminante, competencia de la ASEA.",
      linkpdf: "https://drive.google.com/file/d/12eCVesxEYU9JuogYVi88msai6yh1WPWd/view?usp=sharing"
    },
    {
      id: 1,
      title: "Sitios potencialmente contaminados y sitios contaminados registrados en el SISCO como pasivos ambientales.",
      linkpdf: "https://drive.google.com/file/d/1JUPfFUwVcQp8MLtz_UwV8cmbFSgi_TrC/view?usp=sharing"
    },
  ];
  const books = [
    {
      title: 'Libro 1',
      coverImageUrl: imglibro1,
      bookUrl: 'https://drive.google.com/file/d/1eNd3NCTvDtXf6fxhPEcQ3pqHDpokJYBG/view?usp=sharing',
      bookTitle: 'RESIDUOS SEDESOL',
      description: '-Capítulo 7',
    },
  ];
  const objetivos = [
    {
      id: 1,
      titulo: "¿Qué es un residuo?",
      parrafo: "El artículo 18 de la Ley General para la Prevención y Gestión Integral de Residuos (LGPGIR) establece que los residuos sólidos urbanos podrán subclasificarse en orgánicos e inorgánicos con objeto de facilitar su separación primaria y secundaria, de conformidad con los Programas Estatales y Municipales para la Prevención y la Gestión Integral de los Residuos, así como con los ordenamientos legales aplicables.",
    },
    {
      id: 1,
      titulo: "Orgánicos:",
      parrafo: "Todo desecho de origen biológico que alguna vez estuvo vivo o fue parte de un ser vivo.",
    },
    {
      id: 1,
      titulo: "Inorgánicos:",
      parrafo: "Todo desecho que no es de origen biológico.",
    },
    {
      id: 2,
      titulo: "",
      parrafo: (
        <ul>
          <li>El 80 por ciento de los residuos que se producen diariamente podrían ser tratados de manera que generaran un valor económico, pues el 50 por ciento es materia orgánica que podría regresar a los campos y convertirse en un valioso nutriente para las cosechas.</li>
          <li>El 30 por ciento restante se constituye de papel, vidrio, plástico, metales y otros residuos sólidos valorizables, los cuales podrían ser insertados en los ciclos económicos, generando fuentes de empleo, riqueza y evitando el despilfarro de materias primas.</li>
        </ul>
      ),
    },
    {
      id: 1,
      titulo: "Entrevista:",
      parrafo: "Dr. Alejandro Méndez López. Secretario. Secretaría del Medio Ambiente. 2022.",
    },
  ];
  const images = [
    cinta,
    // Añade aquí las rutas de todas las imágenes que quieras mostrar
  ];
  const objetivos2 = [
    {
      id: 1,
      titulo: "La cinta o banda de Moebius",
      parrafo: "El 22 de abril de 1970, fue la primera celebración del Día de la Tierra. Ese día la compañía Container Corporation of America, dedicada a elaborar cajas de cartón, organizó un concurso para determinar un símbolo para sus productos realizados con papel reciclado, con el deseo de hacerlo también universal. Así fue como más de 500 personas presentaron sus diseños, que debían cumplir ciertos requisitos: simplicidad, inteligibilidad en cualquier escala de impresión, en blanco y negro; además, sería luego de dominio público, es decir, libre para su utilización y sin restricción de derechos de autor. El ganador fue un joven estudiante de arquitectura llamado Gary Anderson, quien curiosamente no tenía especial interés por el diseño, pero que participó del concurso para apoyar la causa ambiental.",
    },
  ];
  return (
    <div className='main-residuos'>
      <h2 className='subtitulo-main-residuos'>Residuos</h2>
      <DocLinks title="Index: documentos" Docts={docts} />
      <DocLinks title="SEMARNAT Estadísticas Sitios contamiandos" Docts={doctsx} />
      <ImgTexto
        imageSrc={grafica1}
        title="De acuerdo con el Diagnóstico Básico para la Gestión Integral de los Residuos, elaborado por la SEMARNAT, en Michoacán se generan 4,459 ton/día de residuos sólidos."
        text="En Michoacán se recolectan por separado 69.51 ton/día de residuos, lo que representa el sólo el 1.6% del total de la generación diaria, mientras que a nivel nacional se recolecta 5,258 ton/día, representando el 5% del total. El 31.56% de los residuos sólidos urbanos se consideran que pueden ser valorizados; el 46.42% corresponde a residuos orgánicos, y el 22.03% es clasificado como otros residuos."
      />
      <ImgTexto
        imageSrc={grafica2}
        title="Porcentaje de participación de vehículos de recolección. Fuente: SEMARNAT, 2020. Diagnostico básico para la gestión integral de los residuos."
        text=""
      />
      <ImgTexto
        imageSrc={grafica3}
        title="Situación Municipal de  los residuos sólidos en el Estado de Michoacán"
        text="En la mayoría de los municipios el sistema de manejo se restringe a las actividades básicas de recolectar los residuos generados, trasladarlos y depositarlos en los Sitios de Disposición Final."
      />
      <Book title="Libro" books={books} />
      <BanerTexto textos={objetivos} />
      <ImageGallery title="La cinta o banda de Moebius" images={images} />
      <BanerTexto textos={objetivos2} />

    </div>
  );
}

export default Residuos;
