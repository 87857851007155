import React from 'react';
import './educacion.css'

import BanerTexto from '../../UI/banerTexto/banerTexto';
import DocLinks from '../../UI/docLinks/docLinks';
import ImageGallery from '../../UI/imageGalery/ImageGallery';
import Book from '../../UI/book/book';

import libro1 from './libro1.png'
import practicas from './Escuelas de Buenas Prácticas.png'

function Educación() {
    const objetivos = [
        {
            id: 1,
            titulo: "Educación ambiental:",
            parrafo: "Proceso de formación dirigido a toda la sociedad, tanto en el ámbito escolar como en el ámbito extraescolar, para facilitar la percepción integrada del ambiente a fin de lograr conductas más racionales a favor del desarrollo social y del ambiente. La educación ambiental comprende la asimilación de conocimientos, la formación de valores, el desarrollo de competencias y conductas con el propósito de garantizar la preservación de la vida. Fuente: LEY AMBIENTAL PARA EL DESARROLLO SUSTENTABLE DEL ESTADO DE MICHOACÁN DE OCAMPO ÚLTIMA REFORMA",
        },
        {
            id: 1,
            titulo: "Cultura Ambiental:",
            parrafo: "El conjunto de conocimientos, hábitos y actitudes, que mueven a una sociedad a proteger el ambiente y a actuar en armonía con la naturaleza, transmitidos a través de generaciones o adquiridos por medio de la educación ambiental. Fuente: LEY AMBIENTAL PARA EL DESARROLLO SUSTENTABLE DEL ESTADO DE MICHOACÁN DE OCAMPO ÚLTIMA REFORMA ",
        },
        {
            id: 1,
            titulo: "",
            parrafo: "El Programa de Sustentabilidad Ambiental Escolar, Escuela de buenas prácticas, fomenta el reconocimiento de la crisis ambiental que vivimos actualmente, el desarrollo de un pensamiento crítico, la autonomía y la acción colectiva. Las escuelas participantes forman un Comité Ambiental Escolar, que coordina la elaboración, el diseño, operación y evaluación del Plan de Acción Ambiental Escolar. Además promueven la realización de acciones y las buenas prácticas ambientalmente sustentables, con las siguientes líneas de acción, las cuales están articuladas de forma transversal por los enfoques de género, interculturalidad y cambio climático:",
        },
        {
            id: 2,
            titulo: "",
            parrafo: (
              <ul>
                <li>1.  Manejo sustentable del agua.</li>
                <li>2. Eficiencia en el consumo de la electricidad.</li>
                <li>3. Educación para el manejo integral sustentable de los residuos sólidos y el consumo sustentable.</li>
                <li>4. Salud y estilo de vida sustentable (Alimentación Consciente y Huertos Escolares).</li>
                <li>5. Educación ambiental.</li>
                <li>6. Acciones Ambientales Comunitarias.</li>
              </ul>
            ),
          }
    ];

    const docts = [
        {
            id: 1,
            title: "Capacitación a unidades de producción en temas ambientales, 2007.",
            linkpdf: "https://drive.google.com/file/d/13qWttWNQ2zhjuW0xV2P5Y3jymV4ZalBq/view?usp=sharing"
        },
        {
            id: 1,
            title: "Población estimada de 3 años y más por sexo y condición de habla indígena.",
            linkpdf: "https://drive.google.com/file/d/1j_uUoKvrIhhLwYpKxSqVDvCNKfGdUFq3/view?usp=sharing"
        },
        {
            id: 1,
            title: "Población estimada de 15 años y más por condición de alfabetismo.",
            linkpdf: "https://drive.google.com/file/d/14nG11rgrfx5DxYs2FUZ2lVt1dE-fd4c5/view?usp=sharing"
        },
        {
            id: 1,
            title: "Población estimada de 15 años y más por sexo, nivel de escolaridad y grado promedio de escolaridad, 2015.",
            linkpdf: "https://drive.google.com/file/d/1RpR2w3bcsbst6b1iPuHZ2qob2sZbo-Kw/view?usp=sharing"
        },
    ];
    const books = [
        {
            title: 'Libro 1',
            coverImageUrl: libro1,
            bookUrl: 'https://drive.google.com/file/d/1E94npByiK-oiSrBJ8HSB6Bl79Qp2eRLL/view?usp=sharing',
            bookTitle: 'Programa Especial de Educación Ambiental para la Sustentabilidad:',
            description: 'Acciones en condiciones de cambio climático en michoacán 2020 - 2022',
        },
    ];
    const imagen = [
        practicas,
    ]
    return (
        <div className='main-educacion'>
            <h2 className='subtitulo-main-educacion'>Educación Ambiental</h2>

            
            <DocLinks title="Estadisticas Federales" Docts={docts} />
            <Book title="Libro" books={books} />
            <ImageGallery title="" images={imagen} />
            <BanerTexto textos={objetivos} />
        </div>
    );
}

export default Educación;
