import React from 'react';
import DocLinks from '../../UI/docLinks/docLinks';
import FolderComponent from '../../UI/carpetas/FolderComponent';

import './planeacion.css'

function Planeación() {
    const docts = [
        {
            id: 1,
            title: "Ordenamiento Ecológico Regional ZIRAHUÉN.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1mM7yv00uq1iNQcJ7ZAPBYuVoi4PoXUjv/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
        },
        {
            id: 1,
            title: "Ordenamiento Ecológico Regional TIERRA CALIENTE.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1Imco2uopwOIUNqTQ62gUuTFFXpnVUPB7/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1pl0Dgggp1q9fZapj3h38WAnlqkDHJW9e/view?usp=sharing"
        },
        {
            id: 1,
            title: "Ordenamiento Ecológico Regional SIERRA COSTA.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1VT-jiyh-DAQMim1fyVlXVzea9Lt7qorI/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1F12TQ6CtMUQcpVf45bOfly7Mhz0Bc-_O/view?usp=sharing"
        },
        {
            id: 1,
            title: "Ordenamiento Ecológico Regional RÍO TEPALCATEPEC.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1mazy2ZLpOkvN5bfY2lQ0eCCWKcok7yeZ/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1ncOQXUQehYJ1rDhdoT60fg8l2d9XXn8I/view?usp=sharing"
        },
        {
            id: 1,
            title: "Ordenamiento Ecológico Regional MARIPOSA MONARCA.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1J2bwVsoC0i12xkMRC-K_0KiTvurFAntp/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1jwlUdYOd3eUCRySIIuEeT1wUMdf_djHj/view?usp=sharing"
        },
        {
            id: 1,
            title: "Ordenamiento Ecológico Regional LAGO DE CUITZEO.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1dDWQLyqEg-8MAkUQ63VQPN6tgU5yjW0D/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1gjuqyFxlFjb2HKnnT4XAEyVOcUvlIXaw/view?usp=sharing"
        },
        {
            id: 1,
            title: "Ordenamiento Ecológico Regional de INFIERNILLO.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1fpHHq6pjI1Qt7EROuH23Dd27Wr8ZQG3B/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1fSmOITYjSdCuUb7VjzDpLzp8ylTztIAu/view?usp=sharing"
        },
        {
            id: 1,
            title: "Ordenamiento Ecológico Regional CUENCA DEL RÍO DUERO.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1uqf0JEI8ffmEc02SJLIdLEq3v4oyAv4B/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1ochg7sei4urWuiNNOWSsQtRFgmaT63an/view?usp=sharing"
        },
        {
            id: 1,
            title: "Ordenamiento ecológico local, regional y estatal concentrado.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1QDNwog-Pr8tjnoL4mq1XbfHcAHQB5Thl/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1nXsgis4eMkQwZF-WZic9fsSZGBXZ9rvd/view?usp=sharing"
        },
        {
            id: 1,
            title: "Ordenamiento Ecológico Local ZIRACUARETIRO.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1plO0eAg8aq8Y33D0NBzQRHw0FJ6ubp-s/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1ZIsl6d2S1TdU9BAFai-zYGjtjqS6sx9m/view?usp=sharing"
        },
        {
            id: 1,
            title: "Ordenamiento Ecológico Local URUAPAN.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1MAwUdSAKhXtbNoSW2LB9PQ0p_dv84qzo/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1DtLxmPSr0uG5u83svRNEpn391TuyKODx/view?usp=sharing"
        },
        {
            id: 1,
            title: "Ordenamiento Ecológico Local SALVADOR ESCALANTE.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1HPzeER6Xc2ylpDu6ynDJ6OC49OWN9eHT/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1uonMe8Qpy0E7SOw9TPvmUliQb8xECC9X/view?usp=sharing"
        },
        {
            id: 1,
            title: "Ordenamiento Ecológico Local MORELIA.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1Gbr63Ab84_Qd7uJwT3TBHBUPsqm7H_um/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1iQgTtsd-ISBub5Weas-z_mXmIdR2p34H/view?usp=sharing"
        },
        {
            id: 1,
            title: "Ordenamiento Ecológico Local LÁZARO CÁRDENAS.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1nyIgYNFiH8Gpd1Ytb8L4m1ixIjaOMdUF/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1xKhRlYEjqLITgaSoOVRmmkyCZ8JPS5f5/view?usp=sharing"
        },
        {
            id: 1,
            title: "Ordenamiento Ecológico Local COTIJA.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1cF822cgV3m4kKoOkvrvXJ1vDA-f2uTww/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1iseexzvrr5iEcljnFCcwbc1cF6cOcP6R/view?usp=sharing"
        },
        {
            id: 1,
            title: "Ordenamiento Ecológico ESTATAL DE MICHOACÁN.",
            linkecxel: "https://docs.google.com/spreadsheets/d/1MPbsqh7Dowmc8j62PKMTqrJwGa_ke5RW/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1u2RnK3aH5Xsst-ogyIPkKhkEmTTFxQKu/view?usp=sharing"
        },

    ];
    const folders = [
        { title: 'ESTATAL DECRETADOS', link: 'https://drive.google.com/drive/folders/1KHgkIQ-hf3tpXoKCKOhVuoDu7IPRIhLe?usp=sharing' },
        { title: 'REGIONALES DECRETADOS', link: 'https://drive.google.com/drive/folders/1hEUoeiiMTsJhi-Dl0XACUzeTtIkQlUA8?usp=drive_link' },
        { title: 'LOCALES DECRETADOS', link: 'https://drive.google.com/drive/folders/15XhLWoPzQmSLM7P5QBBWySdYyekKYsgA?usp=sharing' },
    ];
    return (
        <div className='main-planeacion'>
            <h2 className='subtitulo-main-planeacion'>Bitacora del Ordenamiento Ecológico Territorial</h2>
            <DocLinks title="Index: documentos" Docts={docts} />
            <FolderComponent folders={folders} />
        </div>
    );
}

export default Planeación;
