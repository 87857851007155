import React from 'react';
import './ImgTexto.css';

const ImgTexto = ({ imageSrc, title, text }) => {
  return (
    <div className="imgtexto-main">
      <div className="imgtexto-imagen">
        <img src={imageSrc} alt="Imagen" />
      </div>
      <div className="imgtexto-contenido">
        <div className="imgtexto-titulo">{title}</div>
        <div className="imgtexto-texto">{text}</div>
      </div>
    </div>
  );
};

export default ImgTexto;
