import React from 'react';
import DocLinks from '../../UI/docLinks/docLinks';
import Book from '../../UI/book/book';

import Links from '../../UI/links/Links';
import ImageGallery from '../../UI/imageGalery/ImageGallery';
import './demografia.css'



import imgsuperficie from './Superficie.gif'
import imgmujerhombre from './Mujeres y hombres.png'

import imgnumhabitantes from './Núm Habitantes.png'
import imgestructuraedades from './Estructura de edad.jpg'



import imgmigracion from './Migración causas.jpg'
import imgindigena from './Habla indigena mich.png'


import imglibro1 from './atlas.png'
import imglibro2 from './agenda.png'
import imglibro3 from './atlas2.png'
import imglibro4 from './socioling.png'
import imglibro5 from './resultados.png'
import imglibro6 from './diagnostico.png'
import imglibro7 from './iniciativa.png'
import imglibro8 from './resago.png'
import imglibro9 from './pueblo.png'


function Demografia() {
    const docts = [
        {
            id: 1,
            title: "Censo poblacional por municipio en Michoacán.",
            linkecxel: "https://docs.google.com/spreadsheets/d/19k_e7u1NOKfbEaxOZ4xRNQFjFEZSYNAe/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1Z3gWGt2iYwgmjlCwwDzbYx28FCNX7EYo/view?usp=sharing"
        },
        {
            id: 1,
            title: "Habitantes lengua indigena",
            linkecxel: "https://docs.google.com/spreadsheets/d/12peI7-AycjIwdstE8nkxXzAhKcvLdPAu/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/19hqybeSLuO23eQsMeuFf_Wc_cF5vHlUq/view?usp=sharing"
        },
    ];
    const books = [
        {
            title: 'Libro 1',
            coverImageUrl: imglibro1,
            bookUrl: 'https://drive.google.com/file/d/1y8cwYfPV2596OUFI8ORgDELp7XgHVKsU/view?usp=sharing',
            bookTitle: ' Atlas sociolingüístico de pueblos indígenas en ALC-Tomo 1.',
            description: '-El Fondo de las Naciones Unidas para la Infancia -UNICEF - tiene el gusto de presentar el Atlas sociolingüístico de pueblos indígenas en América Latina...',
        },
        {
            title: 'Libro 1',
            coverImageUrl: imglibro3,
            bookUrl: 'https://drive.google.com/file/d/1g3DcdMpNorZU--Wf4kGlL023kOuwE2tz/view?usp=sharing',
            bookTitle: 'Atlas sociolinguistico de pueblos indigenas en ALC-Tomo 2.',
            description: '-El Fondo de las Naciones Unidas para la Infancia -UNICEF - tiene el gusto de presentar el Atlas sociolingüístico de pueblos indígenas en América Latina...',
        },
        {
            title: 'Libro 1',
            coverImageUrl: imglibro2,
            bookUrl: 'https://drive.google.com/file/d/1D7PoiRFOAd-KeMD9zyfAhakEL33xMIrp/view?usp=sharing',
            bookTitle: 'Agenda 2030 y los Objetivos de Desarrollo Sustentable una Oprtunidad para América latina y El Caribe.',
            description: '-La Agenda 2030 para el Desarrollo Sostenible, aprobada en septiembre de 2015 por la Asamblea General...',
        },
        {
            title: 'Libro 1',
            coverImageUrl: imglibro4,
            bookUrl: 'https://drive.google.com/file/d/1o8CVrvdqm35mClF5ebPNZ7Cm5sH-Iqbi/view?usp=sharing',
            bookTitle: 'Atlas sociolinguistico de pueblos indigenas en ALC-Fe de erratas.',
            description: '-Fe de erratas',
        },
        {
            title: 'Libro 1',
            coverImageUrl: imglibro5,
            bookUrl: 'https://drive.google.com/file/d/1El3lsbc03jjIPE-dJn8zVP33W0mUYUUj/view?usp=sharing',
            bookTitle: 'Censo poblacional en Michoacán RESULTADOS.',
            description: '-Producir información sobre la dimensión, estructura y distribución espacial de la población, así como de sus principales...',
        },
        {
            title: 'Libro 1',
            coverImageUrl: imglibro6,
            bookUrl: 'https://drive.google.com/file/d/1sFtZCOUrxiyRI9BvSXcVUTlov8LJc-xQ/view?usp=sharing',
            bookTitle: 'Diagnóstico estatal de Michoacán Vázquez, Luis (2000).',
            description: '-En el estado de Michoacán los hablantes de purhepecha representan 82.5%, los mazahuas 2.8%, los nahuas 2.6%...',
        },
        {
            title: 'Libro 1',
            coverImageUrl: imglibro7,
            bookUrl: 'https://drive.google.com/file/d/11K7hYtc2LTLU1nCg3dMSURDSPewreIC6/view?usp=sharing',
            bookTitle: 'Iniciativa Latinoamericana y Caribeña para el Desarrollo Sostenible, Mexíco 2012.',
            description: '-En el marco de esta Iniciativa, el documento que ahora presentamos describe el avance reciente de México en el cumplimiento,,,',
        },
        {
            title: 'Libro 1',
            coverImageUrl: imglibro8,
            bookUrl: 'https://docs.google.com/document/d/1YoMGnr-HnkasTSXzevi8lCs6Pmt7Wj4q/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true',
            bookTitle: 'Michoacán rezago social 2023.',
            description: '-ACUERDO por el que se da a conocer el Informe Anual sobre la Situación de Pobreza y Rezago Social de las Entidades, Municipios y Demarcaciones Territoriales para el Ejercicio Fiscal 2023,',
        },
        {
            title: 'Libro 1',
            coverImageUrl: imglibro9,
            bookUrl: 'https://drive.google.com/file/d/1FPVduOJ1yxfGNv1fXQUQfbPL37XYA_Nc/view?usp=sharing',
            bookTitle: 'Pueblo Otomí situación de la lengua.',
            description: '-La Nación tiene una composición pluricultural sustentada originalmente en sus pueblos indígenas que son aquellos que descienden de poblaciones que habitaban en el territorio actual...',
        },
    ];

    const imagen_inicial = [
        imgsuperficie,
        imgmujerhombre
    ];
    const imagen_secundario = [
        imgmigracion,
    ];
    const img_x = [
        imgestructuraedades,
        imgindigena,
        imgnumhabitantes,
    ]
    const links = [
        {
            url: 'https://cuentame.inegi.org.mx/monografias/informacion/mich/poblacion/default.aspx?tema=me&e=16',
            label: 'Fuente: INEGI. 2023.'
        },
    ];
    return (
        <div className='main-demografia'>
            <h2 className='subtitulo-main-demografia'>Demografía</h2>
            <DocLinks title="Index: documentos" Docts={docts} />
            <Book title="Libros" books={books} />
            <ImageGallery title="En México hay 126,014, 024 millones de personas." images={imagen_inicial} />
            <ImageGallery title="Las principales causas por las que migran las personas en Michoacán de Ocampo son:" images={imagen_secundario} />
            <ImageGallery title="" images={img_x} />
            <Links title="Enlaces" links={links} />
        </div>
    );
}

export default Demografia;