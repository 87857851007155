import React from 'react';
import BanerTexto from '../../UI/banerTexto/banerTexto';
import Book from '../../UI/book/book';
import Links from '../../UI/links/Links';
import ImgTexto from '../../UI/img-texto/ImgTexto'

import './cambio.css'

import imglibro1 from './atlas.png'
import imglibro2 from './estidio.png'
import imglibro3 from './comision.png'
import imglibro4 from './estimaciones.png'
import imglibro5 from './programa.png'


import grafica1 from './grafica1.png'

function Cambio() {
    const objetivos2 = [
        {
            id: 1,
            titulo: "",
            parrafo: "El cambio climático es considerado uno de los problemas ambientales más importantes de nuestro tiempo, y puede definirse como todo cambio significativo en el sistema climático del planeta, que permanece por décadas o más tiempo. Julia Carabias. 2017.",
        },
    ];
    const books = [
        {
            title: 'Libro 1',
            coverImageUrl: imglibro1,
            bookUrl: 'https://drive.google.com/file/d/1MyeEzz80BYyT-VH1VZ52vnpiQ8GJgMZk/view?usp=sharing',
            bookTitle: 'Atlas Nacional de Vulnerabilidad al Cambio Climático.',
            description: '-México.',
        },
        {
            title: 'Libro 2',
            coverImageUrl: imglibro2,
            bookUrl: 'https://drive.google.com/file/d/1fy64bDW6l9yaHCd4FUoTOx7UIiy3kdKV/view?usp=sharing',
            bookTitle: 'Diagnóstico de la Vulnerabilidad Cambio Climático.',
            description: '-Morelia Michoacán México.',
        },
        {
            title: 'Libro 3',
            coverImageUrl: imglibro3,
            bookUrl: 'https://drive.google.com/file/d/1wHhWoUdhruuq8ddtshjMNCoV_EScLIwa/view?usp=sharing',
            bookTitle: 'Estrategia nacional REDD',
            description: '-2017-2030 México',
        },
        {
            title: 'Libro 4',
            coverImageUrl: imglibro4,
            bookUrl: 'https://drive.google.com/file/d/1GxLoyXWm813TeBdU-xYPBRjjPD56Gcj2/view?usp=sharing',
            bookTitle: 'Estimaciones de Cambio Climático para Michoacán.',
            description: '-El cambio climático, conocido comúnmente como calentamiento global, es el incremento de la temperatura a nivel mundial...',
        },
        {
            title: 'Libro 5',
            coverImageUrl: imglibro5,
            bookUrl: 'https://drive.google.com/file/d/1zmJkphHezGH2H0YU9qzP7pazhwL6BRVY/view?usp=sharing',
            bookTitle: 'Programa Estatal de Cambio Climatico de Michoacán de Ocampo',
            description: '-PERIÓDICO OFICIAL',
        },
    ];
    const links = [
        { url: 'https://atlasvulnerabilidad.inecc.gob.mx/page/Proyecciones/P_16.html', label: 'Proyecciones de cambio climático y descarga de fichas climáticas por estado NUEVO' },
        { url: 'http://ru.iiec.unam.mx/5457/', label: 'Tendencia de cambio climático para la región Cuitzeo de Michoacán y su asociación con la producción de maíz de temporal' },
        { url: 'http://bibliotecavirtual.dgb.umich.mx:8083/xmlui/handle/DGB_UMICH/418', label: 'Cambio climático y agricultura en la región Tierra Caliente de Michoacán, escenarios' },
        { url: 'https://www.scielo.org.mx/scielo.php?pid=S2007-09342011000800012&script=sci_arttext', label: 'Cambio climático en la zona aguacatera de Michoacán: análisis de precipitación y temperatura a largo plazo' },
        { url: 'https://www.google.com.mx/books/edition/El_cambio_clim%C3%A1tico/YF8yDwAAQBAJ?hl=es&gbpv=1', label: 'El Cambio climático. Causas, efectos y soluciones' },
        { url: 'https://cambioclimatico.gob.mx/estadosymunicipios/Descargas.html', label: 'Instrumentos de política climática, GYCEI, calidad del aire, información de peligros y riesgos, fortalecimiento de capacidades' },
        { url: 'https://sinaica.inecc.gob.mx/', label: 'Sistema Nacional de Información de la Calidad del Aire, SINAICA' },

    ];

    return (
        <div className='main-cambio'>
            <h2 className='subtitulo-main-cambio'>Cambio Climatico</h2>
            <Book title="Libros" books={books} />
            <ImgTexto
                imageSrc={grafica1}
                title="Política  Climática  actual  del Estado de Michoacán"
                text={(
                    <>Como parte de la actual política climática el Estado de Michoacán  se cuenta con 11 instrumentos en la materia:
                        Cambio climático 5 que son:
                        <ol>
                            <li>Ley de Cambio Climático del Estado de Michoacán de Ocampo.</li>
                            <li>Reglamento de la Ley de Cambio Climático del Estado de Michoacán de Ocampo.</li>
                            <li>Evaluación de la Política Estatal de Cambio Climático.</li>
                            <li>Programa Estatal de Cambio Climático del Estado de Michoacán de Ocampo.</li>
                            <li>Fondo Estatal de Cambio Climático.</li>
                        </ol>
                        Gestión Territorial 3:
                        <ol>
                            <li>Programa de Gestión para Mejorar la Calidad del Aire en el Estado de Michoacán 2015-2024.</li>
                        </ol>
                        Manejo de Residuos Sólidos 2:
                        <ol>
                            <li>Ley para la prevención y Gestión Integral de Residuos en el Estado de Michoacán de Ocampo.</li>
                            <li>Programa Estatal para la Prevención y Gestión Integral de Residuos en Michoacán de Ocampo.</li>
                        </ol>
                        Gestión Territorial 3:
                        <ol>
                            <li>Programa Estatal de Ordenamiento Ecológico y Territorial.</li>
                            <li>Sistema Integral de Información de Riesgos del Estado de Michoacán.</li>
                            <li>Programa Sectorial de Desarrollo Territorial, Urbano y Movilidad 2015-2021.</li>
                        </ol>
                    </>
                )}

            />
            <Links title="Enlaces" links={links} />
            <BanerTexto textos={objetivos2} />
        </div>
    );
}

export default Cambio;