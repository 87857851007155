import React from 'react';
import YouTube from 'react-youtube';

import DocLinks from '../../UI/docLinks/docLinks';
import Book from '../../UI/book/book';
import Links from '../../UI/links/Links';
import Enlace from '../../UI/enlaceimg/Enlace';

import imglibro1 from './estategia.png'
import imglibro2 from './biodiversidad vol1.png'
import imglibro3 from './biodiversidad vol2.png'
import imglibro4 from './biodiversidad vol3.png'

import imglibro5 from './crocodylia.PNG'
import imglibro6 from './ambystoma.PNG'
import imglibro7 from './guacamaya verde.PNG'
import imglibro8 from './loro.PNG'
import imglibro9 from './jaguar.PNG'
import imglibro10 from './Felinos.PNG'
import imglibro11 from './cascabel.PNG'
import imglibro12 from './abronia.PNG'
import imglibro13 from './tortuga.PNG'
import imglibro14 from './tortuga laud.PNG'
import imglibro15 from './tortuga golfina.PNG'
import imglibro16 from './zapote.PNG'
import imglibro17 from './anexos.png'

import nauralista from './naturalista.png'

import './biodiversidad.css'

function Biodiversidad() {
  const books = [
    {
      title: 'Libro 1',
      coverImageUrl: imglibro1,
      bookUrl: 'https://drive.google.com/file/d/1dHyAqzVZv-u6_UVOztGVAMbr8PA_LprW/view',
      bookTitle: 'Estrategia para la Conservación y Uso Sustentable de la Diversidad Biológica del Estado de Michoacán',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 2',
      coverImageUrl: imglibro2,
      bookUrl: 'https://drive.google.com/file/d/1sweNJ0rAR4Zl6_nX5Mr6BXmb8L4sY4DJ/view?usp=share_link',
      bookTitle: 'La Biodiversidad en Michacán. Estudio de Estado Volumen I',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 3',
      coverImageUrl: imglibro3,
      bookUrl: 'https://drive.google.com/file/d/1-5_8OHuHvccK_4aXSMxzykkX1FP7ntI-/view?usp=share_link',
      bookTitle: 'La Biodiversidad en Michacán. Estudio de Estado Volumen II',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 3',
      coverImageUrl: imglibro4,
      bookUrl: 'https://drive.google.com/file/d/1sd5lrv1oLK4SovYI6eIt9eN_KcbRKRBS/view?usp=share_link',
      bookTitle: 'La Biodiversidad en Michacán. Estudio de Estado Volumen III',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 3',
      coverImageUrl: imglibro17,
      bookUrl: 'https://drive.google.com/file/d/1mWShyQuE_W7RaLwtutSyELmT_GtJtugH/view?usp=sharing',
      bookTitle: 'ANEXOS. La Biodiversidad en Michoacán. Estudio de Caso.',
      description: '-Descripción breve del libro',
    },
  ];
  const books_paces = [
    {
      title: 'Libro 1',
      coverImageUrl: imglibro5,
      bookUrl: 'https://drive.google.com/file/d/1USokhkd8MUZk2Jnz1DccHuBZgRDpgnJ_/view?usp=share_link',
      bookTitle: 'Programas de Acción para la Conservación de la Especie (PACES) Crocodylia',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 2',
      coverImageUrl: imglibro6,
      bookUrl: 'https://drive.google.com/file/d/1_3Oy3PxUjB74NwZZus0mY62tNZHKkJxZ/view?usp=share_link',
      bookTitle: 'Programas de Acción para la Conservación de la Especie (PACES) Ambystoma',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 3',
      coverImageUrl: imglibro7,
      bookUrl: 'https://drive.google.com/file/d/1RXsTdxBXS71zoCg34x5KWrz4DIB4fFIg/view?usp=share_link',
      bookTitle: 'Programas de Acción para la Conservación de la Especie (PACES) Guacamaya verde',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 3',
      coverImageUrl: imglibro8,
      bookUrl: 'https://drive.google.com/file/d/1ZxXJzlQvUZxGu9sq7vb8_Mvd9KKYGg6M/view?usp=share_link',
      bookTitle: 'Programas de Acción para la Conservación de la Especie (PACES) Loros cabeza y nuca amarilla',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 3',
      coverImageUrl: imglibro9,
      bookUrl: 'https://drive.google.com/file/d/10ipG04mbtyaAqLLMpDb3o8ngpXUf1gzk/view?usp=share_link',
      bookTitle: 'Programas de Acción para la Conservación de la Especie (PACES) Jaguar',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 3',
      coverImageUrl: imglibro10,
      bookUrl: 'https://drive.google.com/file/d/1b4OLUsT1gsV3OqQ16Knaf43MUwGuj75B/view?usp=share_link',
      bookTitle: 'Programas de Acción para la Conservación de la Especie (PACES) Pequeños Felinos',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 3',
      coverImageUrl: imglibro11,
      bookUrl: 'https://drive.google.com/file/d/1SBFS9PY4YVylAtcgaSpiG5VvQ4zaSX0g/view?usp=share_link',
      bookTitle: 'Programas de Acción para la Conservación de la Especie (PACES) Serpientes de cascabel',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 3',
      coverImageUrl: imglibro12,
      bookUrl: 'https://drive.google.com/file/d/1XivJg1JM8DqO07I0l8N7yKgwnE8JGYj5/view?usp=share_link',
      bookTitle: 'Programas de Acción para la Conservación de la Especie (PACES) Abronia',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 3',
      coverImageUrl: imglibro13,
      bookUrl: 'https://drive.google.com/file/d/1W7KnzPAcxEdoAZfFLluDLbarE_bZn1xJ/view?usp=share_link',
      bookTitle: 'Programas de Acción para la Conservación de la Especie (PACES) Tortuga Verde',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 3',
      coverImageUrl: imglibro14,
      bookUrl: 'https://drive.google.com/file/d/1fopIyLXArVeFYxOTLSe8SgKxXvkNwhjS/view?usp=share_link',
      bookTitle: 'Programas de Acción para la Conservación de la Especie (PACES) Tortuga Laúd',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 3',
      coverImageUrl: imglibro15,
      bookUrl: 'https://drive.google.com/file/d/1JXwl6AxafEiGF74YmxyUru2GTrIEyrsL/view?usp=share_link',
      bookTitle: 'Programas de Acción para la Conservación de la Especie (PACES)  Tortuga Golfina',
      description: '-Descripción breve del libro',
    },
    {
      title: 'Libro 3',
      coverImageUrl: imglibro16,
      bookUrl: 'https://drive.google.com/file/d/1XPFPU3EKHDSTNl-y3zktIu-TGtdJtXYP/view?usp=share_link',
      bookTitle: 'Programas de Acción para la Conservación de la Especie (PACES)  Zapote Prieto',
      description: '-Descripción breve del libro',
    },
  ];

  const docts = [
    {
      id: 1,
      title: "Lista de especies exóticas invasoras de flora.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1D14NGUPu0Wf9YBAzD4fhrsMf_KGYBx9O/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1EJ0UeJ1hP0K8F1CH3giwukQWT6aHGk8D/view?usp=drive_link"
    },
    {
      id: 1,
      title: "Lista de especies exóticas invasoras de fauna.",
      linkecxel: "https://docs.google.com/spreadsheets/d/14lTKMgiPbF95TJtxLLXp9W6ZW2NKVbXi/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1OlzqZHAOoZ1L0AALy1u-o9sfzMuFrxXe/view?usp=sharing"
    },
    {
      id: 1,
      title: "Especies endémicas de hongos.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1_4OpaKtjh_aLcioW1ZumHNVfRX51rhWx/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1FQ_xsbkebANFZvQy2fWuw1Rdf1rYCuNs/view?usp=sharing"
    },
    {
      id: 1,
      title: "Lista de especies de anfibios en riesgo.",
      linkecxel: "https://docs.google.com/spreadsheets/d/11EW7vLB-_1U5Qo9RHI-MjFIBXbIz1NQi/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/128x4MAquucPNgZwO2DAG1Y_PgXaKy9CG/view?usp=sharing"
    },
    {
      id: 1,
      title: "Lista de especies de aves en riesgo.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1wAVS2uo5I5qs2Y8NQyWKwOYTD4LGiC6v/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1fQkymhQHkCvIkuzwJg_Br5vsruu6NXsv/view?usp=sharing"
    },
    {
      id: 1,
      title: "Lista de especies de mamiferos en riesgo.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1oxy99mLVm6q5QN93N6JcyGiCV2IdBf-F/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1Vz7bIli1aupcVPI896d4KQKGsdnfOa55/view?usp=sharing"
    },
    {
      id: 1,
      title: "Lista de especies de peces en riesgo.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1nYCHSjjDetoDsmbCMkL_h1q0MfizUQs5/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/10ILYFdvgXi9IDiYN7H2G3-NvbuXXlfRV/view?usp=sharing"
    },
    {
      id: 1,
      title: "Lista de especies de plantas en riesgo.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1FuwfL2Fz25-mrC_slAprhKNacEAoZUSt/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1r0qxjwH_Kb8AQEQTDdE7BJ-xa4jesF4I/view?usp=sharing"
    },
    {
      id: 1,
      title: "Lista de especies de reptiles en riesgo.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1_rxonYs5kBSh0SPuXrwGkGoWANIZo_qG/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1rP8recJGAlzIpNkK1MrtFwo683fz9vzF/view?usp=sharing"
    },
  ];

  const links = [
    { url: 'https://www.naturalista.mx/projects/biodiversidad-de-michoacan', label: 'Naturalista en Michoacán' },
  ];
  return (
    <div className='main-biodiversidad'>
      <h2 className='subtitulo-main-biodiversidad'>Biodiversidad</h2>
      <DocLinks title="Index: documentos" Docts={docts} />
      <Book title="Libros" books={books} />
      <Book title="Libros PACES" books={books_paces} />
      <Enlace
        imagenSrc={nauralista}
        link="https://www.naturalista.mx/projects/biodiversidad-de-michoacan"
      />
      <div className='biodiversidad-container-videos'>
        <YouTube className='video' videoId="Ifx5voBW2RE" />
      </div>
      <div className='biodiversidad-container-videos'>
        <YouTube className='video' videoId="GOY89uHpGGA" />
        <YouTube className='video' videoId="EjkvsLZ5dIU" />
      </div>
      <h3 className='subtitulo-main-biodiversidad-2'>Páginas</h3>
      <iframe src="https://rva.fonotecanacional.gob.mx/fonoteca_itinerante/animales.html" width="80%" height="600" title="Iframe Example"></iframe>
    </div>
  );
}

export default Biodiversidad;
