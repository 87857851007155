import React from 'react';
import DocLinks from '../../UI/docLinks/docLinks';
import ImgTexto from '../../UI/img-texto/ImgTexto'

import './bancos.css'

import grafica1 from './grafica1.png'
import grafica2 from './grafica2.png'
function Bancos() {
    const docts = [
        {
            id: 1,
            title: "Bancos de materiales pétreos ",
            linkecxel: "https://docs.google.com/spreadsheets/d/1Uppu3pk5hszSBCZfypW2NDEeBhIOtfm8/edit?usp=drive_link&ouid=104354510501836391821&rtpof=true&sd=true",
            linkpdf: "https://drive.google.com/file/d/1ukV5nWXzITZ3s_NKn-fCxwupGvpOcA2b/view?usp=sharing",
        },
    ];
    return (
        <div className='main-bancos'>
            <h2 className='subtitulo-main-bancos'>Bancos Petréos</h2>
            <DocLinks title="Index: documentos" Docts={docts} />
            <ImgTexto
                imageSrc={grafica1}
                title="Pesos corrientes 2019"
                text="La producción de minerales no metálicos de competencia estatal, para 2019, el valor volumen de estos agregados fue de 21 mil millones de pesos por correspondientes a 153 millones de toneladas de agregados pétreos en el estado (Servicio Geológico Mexicano -SGM, 2019)."
            />
            <ImgTexto
                imageSrc={grafica2}
                title="Toneladas"
                text="Actualmente, la Secretaría del Medio Ambiente tiene 14 licencias de aprovechamientos pétreos vigentes. SECMA.2023."
            />
        </div>
    );
}

export default Bancos;
