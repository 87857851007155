import React from 'react';
import './areas-conservacion.css'

import FolderComponent from '../../UI/carpetas/FolderComponent';
import DocLinks from '../../UI/docLinks/docLinks';
import ImgTexto from '../../UI/img-texto/ImgTexto'

import grafica1 from './grafica1.png'
import grafica2 from './grafica2.png'
import grafica3 from './grafica3.png'
function AreasConservacion() {
  const docts = [
    {
      id: 1,
      title: "Zonas restauración y protección ambiental.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1xoke3gJZqTpauFWd1M-Zy2GwZ_pz1jFW/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1fPF6MW1HZbqAMA8TwEfzFxCvllR-HaSg/view?usp=sharing",
    },
    {
      id: 1,
      title: "Áreas voluntarias para la conservación.",
      linkecxel: "https://docs.google.com/spreadsheets/d/1LCGaLiadKyk-w0iyFIhyUacYmI4JAhsg/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/102JZTUwkxevajyMTY5-xTaefMZK3V1Jf/view?usp=sharing",
    },
    {
      id: 1,
      title: "Áreas naturales protegidas.",
      linkecxel: "https://docs.google.com/spreadsheets/d/13PARg1d-Zi4VLZptsTSglFxH-yB4eEqF/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true",
      linkpdf: "https://drive.google.com/file/d/1rR0y8jpV31Sz6V9cauYi9O4SNZc1rzno/view?usp=sharing",
    },
  ];
  const folders = [
    { title: 'Acuitzio', link: 'https://drive.google.com/drive/folders/1zgj02YXohst4fICELNmyRjZYoQVdG7gO?usp=sharing' },
    { title: 'Álvaro Obregón', link: 'https://drive.google.com/drive/folders/1QrgldYAoKaajCYARC1hXKiroTnyu7rxo?usp=sharing' },
    { title: 'Apatzingán', link: 'https://drive.google.com/drive/folders/1T1rScPjlrZcHM8FBNFDDXjhFiZ5ddHRt?usp=sharing' },
    { title: 'Aquila', link: 'https://drive.google.com/drive/folders/1iXAZ0oVTyLB6HQi64KykdxXwizeZiMgt?usp=sharing' },
    { title: 'Arteaga', link: 'https://drive.google.com/drive/folders/1NMggDls0Ebe0rVtGQA-OVzxNNzSa8uHL?usp=sharing' },
    { title: 'Briseñas', link: 'https://drive.google.com/drive/folders/1imuCuLhB-OmKEIxPPQu3Mcckm4U__4CM?usp=sharing' },
    { title: 'Chinicuila', link: 'https://drive.google.com/drive/folders/19RJz_9eH2WjRxL1bDkEfNu37aBvxqvBQ?usp=sharing' },
    { title: 'Coalcomán', link: 'https://drive.google.com/drive/folders/1mB8PnfZAXRHtXRDRbkAIk17XA3Ch0rTa?usp=sharing' },
    { title: 'Cotija', link: 'https://drive.google.com/drive/folders/1dKTffJ4uhk5kOljE7diNUP8b9I_2EKtr?usp=sharing' },
    { title: 'Gabriel Zamora', link: 'https://drive.google.com/drive/folders/1mpJN-j_GQIOh1WfS7WzZx7CxXPKs99my?usp=sharing' },
    { title: 'Hidalgo', link: 'https://drive.google.com/drive/folders/1LeaYyYXkcnhOQmiiaLYnPpXpcoeb1fyS?usp=sharing' },
    { title: 'Huandacareo', link: 'https://drive.google.com/drive/folders/1UmcT0u2jPenRsP8xkXMew1KE5ZfuSOr-?usp=sharing' },
    { title: 'Huetamo de Núñez', link: 'https://drive.google.com/drive/folders/12LVO_iNxOBuYa1xx3Smpwtg1Ykb3Jfrp?usp=sharing' },
    { title: 'Indaparapeo', link: 'https://drive.google.com/drive/folders/11lF6Za4UG4AYAEPFJqBIvrPKFnolln76?usp=sharing' },
    { title: 'Ixtlán de Hervores', link: 'https://drive.google.com/drive/folders/1I9nFNjuSBzL768kAe_HOHpShohNk3D6S?usp=sharing' },
    { title: 'Jiménez', link: 'https://drive.google.com/drive/folders/1mLxcfjrpmQ2qYYLTpc6UDYv7OgxTyCCB?usp=sharing' },
    { title: 'Jiquilpan', link: 'https://drive.google.com/drive/folders/1B3SRBYIR0NPvU_QW1vD5ezLAyJzewOoJ?usp=sharing' },
    { title: 'La Huacana', link: 'https://drive.google.com/drive/folders/1a6uSde5UbYh4J-a_STJYwd_mN8DcUJOg?usp=sharing' },
    { title: 'La Piedad', link: 'https://drive.google.com/drive/folders/1anBJYecVM33fHWbXuoDj2gA0OC3HqTBr?usp=sharing' },
    { title: 'Lázaro Cárdenas', link: 'https://drive.google.com/drive/folders/1rYjPNG9vG_Kh7G9jYBG5oOUUQyZOq9S4?usp=sharing' },
    { title: 'Los Reyes', link: 'https://drive.google.com/drive/folders/1q1KOzE12dyFjqtrSsvxSbxcbuyWtEsAr?usp=sharing' },
    { title: 'Marcos Castellanos', link: 'https://drive.google.com/drive/folders/1w6-JPNrTl236viU31qGw1lKjoKzoEqk0?usp=sharing' },
    { title: 'Morelia', link: 'https://drive.google.com/drive/folders/1-W9u40mkgfIzQ2__6hOvqRlU8Qri68Cq?usp=sharing' },
    { title: 'Paracho', link: 'https://drive.google.com/drive/folders/18SIv0nFFUCK5nes2oFqtOsEianV0bmGc?usp=sharing' },
    { title: 'Parácuaro', link: 'https://drive.google.com/drive/folders/1sdUKkRpLQFZY-nT2dDpgq7kdn3fYpHGC?usp=sharing' },
    { title: 'Pátzcuaro', link: 'https://drive.google.com/drive/folders/1AvU3Ubeac2cH0Atx812xHYyHW9xZZ_CL?usp=sharing' },
    { title: 'Puruandiro', link: 'https://drive.google.com/drive/folders/1aBecA9XS_WAUVhz8eujELrjZ0Guw_3YQ?usp=sharing' },
    { title: 'Quiroga', link: 'https://drive.google.com/drive/folders/1r2ZuxR_U5u7zhq8VSqAJ4pAuZBW_q5hd?usp=sharing' },
    { title: 'Tacámbaro', link: 'https://drive.google.com/drive/folders/1m-72erEaZbOg6q-8qITECvB-KKLSs66Q?usp=sharing' },
    { title: 'Tancitaro', link: 'https://drive.google.com/drive/folders/1HkH2hjus2gD2bxzRtJjRgdUPla2DQ4wp?usp=sharing' },
    { title: 'Tangancícuaro', link: 'https://drive.google.com/drive/folders/1xbrq1PfkZsB5zkvmvjR8Dib8l7ERU9ny?usp=sharing' },
    { title: 'Tarímbaro', link: 'https://drive.google.com/drive/folders/1uQUWgBZUogXrX48FLMvDOoh4N6EkADjT?usp=sharing' },
    { title: 'Tingambato', link: 'https://drive.google.com/drive/folders/1SJ-IJn9H2sLMXa9nnkPY0a81nxW6vkv4?usp=sharing' },
    { title: 'Tocumbo', link: 'https://drive.google.com/drive/folders/1QF_oh-fy8bpIAzPKrzzQEudRjxZ1AOyf?usp=sharing' },
    { title: 'Tzintzuntzan', link: 'https://drive.google.com/drive/folders/1YG1A8-00EMzYcVlQnnqWSMqNHDdZ3yoj?usp=sharing' },
    { title: 'Tzitzio', link: 'https://drive.google.com/drive/folders/1W3nr-Q4RGCsayKOSyPx5uZyP-r5KC5Ro?usp=sharing' },
    { title: 'Uruapan', link: 'https://drive.google.com/drive/folders/1mdmA09NEhqFS_eCyfqRmu5gx2qOR7A-y?usp=sharing' },
    { title: 'Zacapu', link: 'https://drive.google.com/drive/folders/1PVM2Q35Bx0ibmlkYNPJ-sfjhNc7ztWLy?usp=sharing' },
    { title: 'Ziracuaretiro', link: 'https://drive.google.com/drive/folders/1vrXFj9Jcmq60Askw_bHJKD1bIXeyJfui?usp=sharing' },
    { title: 'Zitácuaro', link: 'https://drive.google.com/drive/folders/1i5UWpwHTNZUTUvZNZvIdDs7rl0xVTi_I?usp=sharing' },
  ];
  return (
    <div className='main-areas'>
      <h2 className='subtitulo-main-areas'>Áreas para la conservación</h2>
      <DocLinks title="Index: documentos" Docts={docts} />
      <ImgTexto
        imageSrc={grafica1}
        title="Comparativa de superficie de áreas de conservación federales y estatales al 2021"
        text="En lo que respecta a la conservación del patrimonio natural, el estado a través del Sistema Estatal de Áreas para la Conservación del Patrimonio Natural y Patrimonio Ambiental contaba un total de 59 Áreas de Conservación con un total de 72,418.15 Hectáreas, y las 12 áreas de carácter federal con 441,711.34950 hectáreas, lo que significa que el 7.5 % del territorio estatal se encuentra con algún esquema de protección formal.

        Para el 2021, de las Áreas de Conservación Estatales, el mayor número correspondía a la categoría de Áreas Naturales Protegidas con un total de 30 áreas decretadas, seguidas de las 20 Áreas Voluntarias para la Conservación y por último las 9 zonas de Restauración y/o Protección Ambiental. Fuente: SECMA 2023.
        "
      />
      <ImgTexto
        imageSrc={grafica2}
        title="Porcentaje de Áreas de Conservación Estatal al 2021."
        text="Las áreas naturales protegidas al 2021 ocupan una superficie de  18%  y en cambio las zonas de restauración y/o protección 72%. "
      />
<FolderComponent folders={folders} />
    </div>
  );
}

export default AreasConservacion;
