import React from 'react';
import './instrumentos.css'

import ImageSlider from '../../UI/slider-imagenes/imageSlider';
import Book from '../../UI/book/book';

import img1 from './1.jpeg'
import img2 from './2.jpeg'
import img3 from './3.jpeg'
import img4 from './4.jpeg'
import img5 from './5.jpeg'
import img6 from './6.jpeg'
import img7 from './7.jpeg'
import img8 from './8.jpeg'
import img9 from './9.jpeg'
import img10 from './10.jpeg'
import img11 from './11.jpeg'
import img12 from './12.jpeg'

import imglibro1 from './libro.png'
function Instrumentos() {
    const ximagenes = [
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        img7,
        img8,
        img9,
        img10,
        img11,
        img12,
    ];
    const books = [
        {
            title: 'Libro 1',
            coverImageUrl: imglibro1,
            bookUrl: 'https://docs.google.com/presentation/d/15OsyspjSyZifLoMnNKSa9Da_NDA3EdiQ/edit?usp=sharing&ouid=104354510501836391821&rtpof=true&sd=true',
            bookTitle: 'INFORME FINAL OBRAS DE CONSERVACIÓN',
            description: '“OBRAS PARA EL CONTROL DE LA EROSIÓN EN CÁRCAVAS EN EL CERRO EL COBRERO, DEL EJIDO PATUÁN. MUNICIPIO DE ZIRACUARETIRO, MICHOACÁN.”',
        },
    ];

    return (
        <div className='main-suelo'>
            <h2 className='subtitulo-main-suelo'>Proyectos e Instrumentos Económicos</h2>

            <Book title="Presentación" books={books} />
            <ImageSlider title="Fotografías" images={ximagenes} />
        </div>
    );
}

export default Instrumentos;
